import { MutableRefObject, useCallback } from 'react';

import { createFileFromArrayBuffer, createFileFromBase64OrString } from './utils';
import { dropSilentMessages, silentMessages } from '../../models/conversations/canvas/silentMessage.signal';
import { dropFilesToUpload, filesToUpload } from '../../models/conversations/canvas/uploadFiles.signal';
import useApiService from '../../services/useApiService';

export const useUpdateExternalInstancesState = (
  updateFileInCanvasAppInstance: MutableRefObject<(instanceId: string, url: string) => unknown>,
  conversationId?: string
) => {
  const { updateExternalInstanceDepiction, uploadFile } = useApiService();

  return useCallback(async () => {
    if (!conversationId) return;
    if (filesToUpload) {
      for (let fileToUpload of filesToUpload) {
        if (typeof fileToUpload.content === 'string') {
          let {
            data: { url },
          } = await uploadFile(
            await createFileFromBase64OrString(fileToUpload.content, fileToUpload.fileName, fileToUpload.fileType)
          );
          updateFileInCanvasAppInstance.current(fileToUpload.instanceId, url);
        } else {
          let {
            data: { url },
          } = await uploadFile(
            await createFileFromArrayBuffer(
              fileToUpload.content,
              fileToUpload.fileName,
              fileToUpload.fileType || 'image/png'
            )
          );
          updateFileInCanvasAppInstance.current(fileToUpload.instanceId, url);
        }
      }
      dropFilesToUpload();
    }

    if (silentMessages.value) {
      await Promise.all(
        silentMessages.value.map(silentMessage =>
          updateExternalInstanceDepiction(
            conversationId,
            silentMessage.instanceId,
            undefined,
            JSON.stringify(silentMessage.content)
          )
        )
      );
    }
    dropSilentMessages();
  }, [conversationId, updateExternalInstanceDepiction, updateFileInCanvasAppInstance, uploadFile]);
};
