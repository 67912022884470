import React, { memo, useCallback, useMemo } from 'react';

import { Modal, Spinner, useTranslation } from '@just-ai/just-ui';
import { useSignalEffect } from '@preact/signals-react';
import cn from 'classnames';

import useUpdateAgentSettings from './hooks/useUpdateAgentSettings';
import { isOpenAgentSettings } from './signals';
import styles from './style.module.scss';
import { useAppContext } from '../../contexts/appContext';
import { isConversationBuilding } from '../../models/conversations';
import { dropSilentMessages } from '../../models/conversations/canvas/silentMessage.signal';
import { dropFilesToUpload } from '../../models/conversations/canvas/uploadFiles.signal';
import { AgentType, CreatedAgent } from '../../types/agents';
import { Conversation } from '../../types/chat';
import { isMobile } from '../../utils/app/common';
import TemplateForm from '../Agents/components/TemplateForm';
import { FormBuilderProvider } from '../Agents/components/TemplateForm/useFormBuilder';
import { appHasUnsavedChanges, resetContextSilent } from '../Chat/signals/ChatUpdateSignal';

interface Props {
  selectedConversation: Conversation;
  hidden: boolean;
}

const AgentSettingsSidebar = memo(({ selectedConversation, hidden }: Props) => {
  const {
    addAlert,
    state,
    state: { locale },
  } = useAppContext();

  const { t } = useTranslation();

  const {
    loading,
    errorState,
    updateData,
    settingsFormRef,
    setUpdateData,
    handleAgentUpdate,
    handleCancelAgentUpdate,
    handleUploadFile,
  } = useUpdateAgentSettings(selectedConversation);

  const template: CreatedAgent = useMemo(
    () => ({
      ...{
        ...selectedConversation.config,
        name: selectedConversation.name,
        params: selectedConversation.config.params || (selectedConversation.app.params as Record<string, unknown>),
      },
      id: selectedConversation.app.id,
    }),
    [selectedConversation]
  );

  const onSubmitClick = useCallback(
    (data: AgentType) => {
      setUpdateData(data);
    },
    [setUpdateData]
  );

  useSignalEffect(() => {
    if (resetContextSilent.value) {
      handleAgentUpdate(selectedConversation.app).then(() => {
        addAlert(t('agentContextReloadSuccess'), 'success');
        resetContextSilent.value = false;
        dropSilentMessages();
        dropFilesToUpload();
      });
    }
  });

  if (selectedConversation.app.template === 'toolAssistant' || selectedConversation.isCanvasChat) return null;

  return (
    <div
      className={cn(styles.agentSettings__wrapper, {
        'd-none': hidden,
        visible: isOpenAgentSettings.value,
      })}
    >
      {loading && <Spinner bluredBackground />}

      <FormBuilderProvider
        data={{
          userId: state.userId,
          template,
          isEdit: true,
          isOpenFromTelegram: false,
          submit: onSubmitClick,
          cancel: () => {},
        }}
      >
        {({ formMethods, onSubmit }) => {
          settingsFormRef.current = formMethods;
          return (
            <>
              <TemplateForm
                isOpenFromTelegram={false}
                formMethods={formMethods}
                cancel={() => {}}
                isEdit
                locale={locale}
                template={template}
                submit={onSubmit}
                handleFileUpload={handleUploadFile}
                disabledUpdateButton={isConversationBuilding(selectedConversation.id)}
                isDirtyCallback={isDirty => {
                  appHasUnsavedChanges.value = isDirty;
                }}
                errorState={errorState}
              />
              {updateData && (
                <Modal
                  className='mobileBottomModal'
                  isOpen={!!updateData}
                  onActionClick={() => handleAgentUpdate(updateData)}
                  onCancelClick={handleCancelAgentUpdate}
                  title={t('agentUpdateModalTitle')}
                  buttonSubmitTestId='AgentSettings:Update:Submit'
                  buttonCancelText={t('cancel')}
                  buttonCancelTestId='AgentSettings:Update:Cancel'
                  buttonSubmitText={t('Confirm:Template:Update')}
                  buttonCancelColor={isMobile() ? 'secondary' : 'primary'}
                >
                  {t('agentUpdateModalText')}
                </Modal>
              )}
            </>
          );
        }}
      </FormBuilderProvider>
    </div>
  );
});

AgentSettingsSidebar.displayName = 'AgentSettingsSidebar';

export default AgentSettingsSidebar;
