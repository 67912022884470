import { useState, useEffect } from 'react';

import { ConversationAndAppCreateRequestApp } from '@just-ai/api/dist/generated/AppsAdapter';
import { usePromiseProcessing } from '@just-ai/just-ui';

import { isAlpha } from '../../../isAlpha';
import { appsAdapterService } from '../../../services/service';
import { generateDefaultName, MAIN_TEMPLATE_NAME } from '../../../utils/app/conversation';

export enum MainConversationRequestStatus {
  NOT_REQUESTED,
  GET_REQUESTED,
  CREATE_REQUESTED,
}

function useGetMainConversationRequest({ abortController }: { abortController?: AbortController } = {}) {
  const [getUserChatsStatus, requestGetUserChats] = usePromiseProcessing(
    (...args: Parameters<typeof appsAdapterService.getUserChats>) => appsAdapterService.getUserChats(...args),
    { throwOnError: true }
  );

  const mainConversationQuery = {
    match: { 'apps.template': MAIN_TEMPLATE_NAME },
    sortBy: { created: -1 as const },
    limit: 1,
  };

  return {
    ...getUserChatsStatus,
    result: getUserChatsStatus.result?.data?.conversations?.[0],
    request: () =>
      requestGetUserChats(mainConversationQuery, abortController).then(
        response => response?.data?.conversations?.[0] ?? null
      ),
  };
}

export function useCreateMainConversationRequest({ abortController }: { abortController?: AbortController } = {}) {
  const [createUserConversationStatus, requestCreateUserConversation] = usePromiseProcessing(
    (...args: Parameters<typeof appsAdapterService.createUserConversation>) =>
      appsAdapterService.createUserConversation(...args),
    { throwOnError: true }
  );

  return {
    ...createUserConversationStatus,
    result: createUserConversationStatus.result?.data,
    request: () =>
      requestCreateUserConversation(
        {
          app: { template: MAIN_TEMPLATE_NAME } as ConversationAndAppCreateRequestApp,
          name: generateDefaultName(MAIN_TEMPLATE_NAME),
        },
        true,
        abortController
      ),
  };
}

/**
 * MainConversation is only about conversation with special button to open.
 * It is not required to be mainAssistant in general, but equals in current design
 */
export default function useMainConversation({
  enabled = false,
  abortController,
}: {
  enabled?: boolean;
  abortController?: AbortController;
} = {}) {
  const [requestStatus, setRequestStatus] = useState<MainConversationRequestStatus>(
    MainConversationRequestStatus.NOT_REQUESTED
  );

  const {
    request: requestGetMainConversation,
    reset: getReset,
    ...getStatus
  } = useGetMainConversationRequest({ abortController });

  const {
    request: requestCreateMainConversation,
    reset: createReset,
    ...createStatus
  } = useCreateMainConversationRequest({ abortController });

  useEffect(() => {
    const notYetRequested = requestStatus === MainConversationRequestStatus.NOT_REQUESTED;
    if (notYetRequested && !isAlpha && enabled) {
      requestMainConversation();
    }

    async function requestMainConversation() {
      setRequestStatus(MainConversationRequestStatus.GET_REQUESTED);
      const getResponse = await requestGetMainConversation();
      if (getResponse) return;

      setRequestStatus(MainConversationRequestStatus.CREATE_REQUESTED);
      await requestCreateMainConversation();
    }
  }, [enabled, requestStatus, requestGetMainConversation, requestCreateMainConversation, getReset]);

  const actualRequestStatus = requestStatus < MainConversationRequestStatus.CREATE_REQUESTED ? getStatus : createStatus;
  const loaded = requestStatus !== MainConversationRequestStatus.NOT_REQUESTED && !actualRequestStatus.loading;

  return {
    ...actualRequestStatus,
    loaded,
    invalidate: () => {
      setRequestStatus(MainConversationRequestStatus.NOT_REQUESTED);
    },
  };
}
