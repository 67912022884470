import { ConversationResponse, TemplateResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { format } from 'date-fns';

import { CategoryValue } from '../../components/Agents/Categories';
import localize from '../../localization';
import { AgentType } from '../../types/agents';
import { Conversation } from '../../types/chat';
import { DEFAULT_AGENT_CONFIG } from '../../utils/app/const';
import { processConversationContext, processHistory } from '../../utils/app/conversation';

const DEPRECATED_ASSISTANT_TEMPLATE_NAME = 'main';

export const rawConversationToConversation = (
  rawChat: ConversationResponse,
  chatTemplate: TemplateResponse
): Conversation => {
  const processedHistory = processHistory(rawChat.history);
  const processedContext = processConversationContext(processedHistory);

  return {
    ...rawChat,
    name: resolveChatName(rawChat, chatTemplate),
    config: {
      ...DEFAULT_AGENT_CONFIG,
      ...rawChat.app,
      categories: chatTemplate.categories as CategoryValue[],
      params: rawChat.app.params as AgentType['params'],
      info: chatTemplate.info,
    },
    history: processedHistory,
    contextValue: processedContext,
  };
};

function resolveChatName(rawChat: ConversationResponse, chatTemplate: TemplateResponse) {
  if (rawChat.app.template === DEPRECATED_ASSISTANT_TEMPLATE_NAME) {
    return localize.translate('assistantJay:old');
  }

  if (rawChat.name) {
    return rawChat.name;
  }

  const templateTitle = chatTemplate.info?.title || rawChat.app.template || 'Assistant';
  const chatDate = format(rawChat.createdAt || new Date(), 'HH:mm dd.MM');
  return `${templateTitle} - ${chatDate}`;
}
