import {
  ConversationResponse,
  MessageResponse,
  MessageStatus,
  MessageType,
} from '@just-ai/api/dist/generated/AppsAdapter';
import { Subscription } from 'rxjs';

import { AgentType, ValidationTemplate } from './agents';

export type AgentTemplateResponse = {
  template: string;
  type: string;
  params: { [key: string]: any };
};

export type TextMessagePart = {
  type: 'text';
  text: string;
  localizationKey?: string;
  payload?: Record<string, any>;
};
export type FileMessagePart = {
  type: 'file';
  name?: string;
  url?: string;
  fileId?: string;
  file?: File;
  mimeType?: string;
};
export type LinkMessagePart = { type: 'link'; name?: string; url: string; fileId?: string };
export type SlideMessagePart = Omit<LinkMessagePart, 'type'> & {
  type: 'slide';
};
export type AppMessagePart = { type: 'app'; app: AgentTemplateResponse };
export type ImageMessagePart = { type: 'image'; url: string; fileId?: string };
export type AudioMessagePart = { type: 'audio'; url: string; fileId?: string };

export type ToolCallItem = {
  function: {
    name: 'show_ui' | string;
    arguments: string;
  };
  id?: string;
};
export type ToolCallMessagePart = {
  type: 'toolCall';
  toolCalls: ToolCallItem[];
};

export type ToolResponseItem = {
  toolCallId: string;
  name: string;
  response: string;
};

export type ToolResponseMessagePart = {
  type: 'toolResponse';
  toolResponse: ToolResponseItem[];
};

// I was considering whether to pass instructions in the AgentInstructions format, similar to how it's done on the backend.
// This would allow any tool call to be invoked from the frontend without checks. Still it doesn't seem too risky.
// However, for now, I've decided to keep a more restricted structure.
// In any case, it can be quickly reworked into AgentInstructions if needed.
export type Instruction =
  | {
      promptKey: string;
      source?: [string]; // [messageId]
    }
  | {
      externalAppId?: string;
      command: string;
      promptKey?: string;
      params?: string;
      source?: [string]; // [messageId]
    };

export type InstructionsMessagePart = {
  type: 'instruction';
  instructions: Instruction[];
};

export type SystemUpdateMessagePart = {
  type: 'systemAppUpdate';
  newAppParams: Record<string, any>;
  oldAppParams: Record<string, any>;
};
export type AutoContextResetMessagePart = { type: 'autoContextReset' };

export type MessagePart =
  | TextMessagePart
  | FileMessagePart
  | LinkMessagePart
  | SlideMessagePart
  | AppMessagePart
  | ImageMessagePart
  | AudioMessagePart
  | ToolCallMessagePart
  | ToolResponseMessagePart
  | InstructionsMessagePart
  | SystemUpdateMessagePart
  | AutoContextResetMessagePart;

export type CopyMessagePart = TextMessagePart | LinkMessagePart | ImageMessagePart;

export type MessageContent = Array<MessagePart>;

export function isMessageText(message: MessagePart): message is TextMessagePart {
  return message.type === 'text';
}

export function isMessageFile(message: MessagePart): message is FileMessagePart {
  return message.type === 'file';
}

export function isMessageLink(message: MessagePart): message is LinkMessagePart {
  return message.type === 'link';
}

export function isMessageApp(message: MessagePart): message is AppMessagePart {
  return message.type === 'app';
}

export function isMessageToolCall(message: MessagePart): message is ToolCallMessagePart {
  return message.type === 'toolCall';
}

export function isMessageToolResponse(message: MessagePart): message is ToolResponseMessagePart {
  return message.type === 'toolResponse';
}
export function isMessageInstructions(message: MessagePart): message is InstructionsMessagePart {
  return message.type === 'instruction';
}

export function isRegeneratableMessagePart({ type }: MessagePart) {
  return (
    type === 'audio' || type === 'file' || type === 'image' || type === 'link' || type === 'slide' || type === 'text'
  );
}

export interface Message extends Omit<MessageResponse, 'type' | 'content' | 'meta' | 'status'> {
  type: `${MessageType}`;
  content: MessageContent;
  status: `${MessageStatus}`;
  meta?: { tokensSpent?: number };
  entities?: ValidationTemplate;
}

export interface ChatBody {
  messages: Message[];
  key: string;
  prompt: string;
  temperature: number;
}

export type ConversationContext = {
  fullness: number;
  messagesTruncationMode: boolean;
};

export interface Conversation extends Omit<ConversationResponse, 'history'> {
  config: AgentType;
  messageIsStreaming?: boolean;
  newMessage?: Message;
  defaultTitle?: string;
  history: Message[];
  streamSubscription?: Subscription;
  contextValue: ConversationContext;
  isCanvasChat?: boolean;
}

export interface LinkMessage {
  text: string;
  url: string;
}

export type ChatRequest =
  | {
      text: string;
      file?: File;
      fileId?: never;
      toolResponse?: string;
      instructions?: string;
    }
  | {
      text: string;
      file?: never;
      fileId?: string;
      toolResponse?: string;
      instructions?: string;
    };

export interface ChatResponse {
  conversationId: string;
  history: Message[];
  response: {
    text: string;
  };
}
