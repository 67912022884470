import React, { memo, useCallback, useMemo } from 'react';

import { Modal } from '@just-ai/just-ui';

import useUpdateAgentSettings from './hooks/useUpdateAgentSettings';
import styles from './style.module.scss';
import { useAppContext } from '../../contexts/appContext';
import { isConversationBuilding } from '../../models/conversations';
import { AgentType } from '../../types/agents';
import { CreatedAgent } from '../../types/agents';
import { Conversation } from '../../types/chat';
import TemplateForm from '../Agents/components/TemplateForm';
import TemplateFormButtons from '../Agents/components/TemplateForm/TemplateFormButtons';
import { FormBuilderProvider } from '../Agents/components/TemplateForm/useFormBuilder';
import { appHasUnsavedChanges } from '../Chat/signals/ChatUpdateSignal';

interface Props {
  isSettingsModalShown: boolean;
  onCloseSettingsModal: () => void;
  selectedConversation: Conversation;
}

const AgentSettingsModal = memo(({ isSettingsModalShown, onCloseSettingsModal, selectedConversation }: Props) => {
  const {
    state,
    state: { locale },
  } = useAppContext();

  const { loading, errorState, settingsFormRef, handleAgentUpdate, handleUploadFile } =
    useUpdateAgentSettings(selectedConversation);

  const template: CreatedAgent = useMemo(
    () => ({
      ...{
        ...selectedConversation.config,
        name: selectedConversation.name,
        params: selectedConversation.config.params || (selectedConversation.app.params as Record<string, unknown>),
      },
      id: selectedConversation.app.id,
    }),
    [selectedConversation]
  );

  const onSubmitClick = useCallback(
    (data: AgentType) => {
      handleAgentUpdate(data);
      onCloseSettingsModal();
    },
    [handleAgentUpdate, onCloseSettingsModal]
  );

  return (
    <FormBuilderProvider
      data={{
        userId: state.userId,
        template,
        isEdit: true,
        isOpenFromTelegram: false,
        submit: onSubmitClick,
        cancel: onCloseSettingsModal,
      }}
    >
      {({ formMethods, onSubmit }) => {
        settingsFormRef.current = formMethods;
        return (
          <Modal
            isOpen={isSettingsModalShown}
            inProgress={loading}
            onClickCloseIcon={onCloseSettingsModal}
            className={styles.agentSettings__modal}
            customFooter={
              <div className='flex flex-md-row gap-8'>
                <TemplateFormButtons
                  template={template}
                  onSubmitClick={onSubmit}
                  onCancelClick={onCloseSettingsModal}
                  isFullWidth={false}
                />
              </div>
            }
          >
            <TemplateForm
              isOpenFromTelegram={false}
              formMethods={formMethods}
              cancel={() => {}}
              isEdit
              showFooter={false}
              locale={locale}
              template={template}
              submit={onSubmit}
              handleFileUpload={handleUploadFile}
              disabledUpdateButton={isConversationBuilding(selectedConversation.id)}
              isDirtyCallback={isDirty => {
                appHasUnsavedChanges.value = isDirty;
              }}
              errorState={errorState}
            />
          </Modal>
        );
      }}
    </FormBuilderProvider>
  );
});

AgentSettingsModal.displayName = 'AgentSettingsModal';

export default AgentSettingsModal;
