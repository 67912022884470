import React, { Component, FC, createContext, useCallback, useContext, useRef } from 'react';

const Localize = require('localize');
export const TranslationContext = createContext({} as TranslationContextType);

export const TranslationContextProvider: FC<TranslationProps> = ({ children, t, tWithCheck, getLocale }) => {
  return <TranslationContext.Provider value={{ t, tWithCheck, getLocale }}>{children}</TranslationContext.Provider>;
};
export type LocalizationObject = Record<string, Record<string, string>>;

export const TranslationContextCustomLocalization: FC<{
  localizationObject?: LocalizationObject;
  children: React.ReactNode;
}> = ({ localizationObject = {}, children }) => {
  const { t, tWithCheck, getLocale } = useTranslation();
  const localizationInstance = useRef(new Localize(localizationObject));

  const tLocal = useCallback(
    (code: string, ...args: any) => {
      localizationInstance.current.setLocale(getLocale());
      if (localizationInstance.current.translate(code) !== code) {
        return localizationInstance.current.translate(code, ...args);
      }
      return t(code, ...args);
    },
    [getLocale, t]
  );

  return (
    <TranslationContext.Provider value={{ t: tLocal, tWithCheck, getLocale }}>{children}</TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);

export type TranslateType = (code: string, ...args: any) => string;
export type TranslationContextType = {
  t: TranslateType;
  tWithCheck: TranslateType;
  getLocale: () => string;
};
export type TranslationProps = TranslationContextType & { children: React.ReactNode };
export function withTranslation(t: TranslationContextType['t']) {
  return function <TProps extends Pick<TranslationProps, 't'>>(WrappedComponent: React.ComponentType<TProps>) {
    type Props = Omit<TProps, 't'>;
    return class ComponentWithTranslation extends Component<Props> {
      public static displayName = `withTranslation(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;
      public render() {
        return <WrappedComponent {...(this.props as TProps)} t={t} />;
      }
    };
  };
}

export function withTranslationContext<TProps>(WrappedComponent: React.ComponentType<TProps>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Component = (props: Omit<TProps, 't'>) => {
    const { t } = useTranslation();
    return <WrappedComponent {...(props as TProps)} t={t} />;
  };

  Component.displayName = `withTranslationContext(${displayName})`;

  return Component;
}
