import React, { FC, memo, useState } from 'react';

import { Button, IconButton, useTranslation } from '@just-ai/just-ui';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import { programmingLanguages } from '../../utils/app/codeblock';
import { generateRandomString } from '../../utils/app/common';

interface Props {
  language: string;
  value: string;
}

export const CodeBlock: FC<Props> = memo(({ language, value }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState<Boolean>(false);

  const copyToClipboard = () => {
    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      return;
    }

    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };
  const downloadAsFile = () => {
    const fileExtension = programmingLanguages[language] || '.file';
    const suggestedFileName = `file-${generateRandomString(3, true)}${fileExtension}`;
    const fileName = window.prompt(t('Enter file name') || '', suggestedFileName);

    if (!fileName) {
      // user pressed cancel on prompt
      return;
    }

    const blob = new Blob([value], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <div className='codeblock relative font-sans text-[16px] dark grid'>
      <div className='flex items-center justify-between py-1.5 px-4'>
        <span className='text-xs lowercase text-white'>{language}</span>

        <div className='flex items-center'>
          <Button
            iconLeft={isCopied ? 'farCheck' : 'farClipboard'}
            className='flex items-center'
            flat
            onClick={copyToClipboard}
          >
            {isCopied ? t('Copied!') : t('Copy code')}
          </Button>
          <IconButton flat name='farDownload' className='flex items-center' onClick={downloadAsFile} />
        </div>
      </div>

      <SyntaxHighlighter language={language} style={oneDark} customStyle={{ margin: 0 }}>
        {value}
      </SyntaxHighlighter>
    </div>
  );
});
CodeBlock.displayName = 'CodeBlock';
