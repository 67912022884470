import React, { useCallback } from 'react';

import { IconButton } from '@just-ai/just-ui';
import { IconButtonProps } from '@just-ai/just-ui/src/lib/Button';
import cn from 'classnames';

import { useAppContext, useSidebarActions } from '../../../contexts/appContext';
import { GA } from '../../../utils/app/common';
import styles from '../style.module.scss';

interface Props {
  onClick: any;
  side: 'left' | 'right';
  className?: string;
  icon?: IconButtonProps['name'];
  size?: IconButtonProps['size'];
}

export const ToggleSidebarButton = ({ onClick, side, icon = 'faBars', size, className = '' }: Props) => {
  const { state } = useAppContext();
  const [, , isChatBarShown] = useSidebarActions();

  const onClickHandle = useCallback(() => {
    GA('ToggleSidebarButton', 'sidebar', isChatBarShown ? 'close' : 'open');
    onClick();
  }, [isChatBarShown, onClick]);

  return (
    <IconButton
      className={cn(`${side} ${state.lightMode}`, styles.sidebar__btn, className)}
      onClick={onClickHandle}
      name={icon}
      size={size}
      color='secondary'
      flat
    />
  );
};
