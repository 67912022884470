import React, { memo, useCallback, useMemo, useState } from 'react';

import { Button, Icon, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { isEqual, omit } from 'lodash';

import { AddToFavouriteModal } from './AddToFavouriteModal';
import { isAddToFavoriteModalOpen, setAddToFavoriteModalOpenTrue } from './AddToFavouriteModal/modal.control';
import { isDemoMode } from '../../../api/client';
import { showSignupModal } from '../../../hooks/showSignup';
import { TFavouriteElement, toggleFavourite } from '../../../models/favourite/favourite.api';
import { CreatedAgent } from '../../../types/agents';
import { DeleteFromFavouriteModal } from '../TemplatesList/DeleteFromFavouriteModal';
import { hideDeleteFavoriteModal, showDeleteFavoriteModal } from '../TemplatesList/favoriteButtons.control';

export const AddToFavoriteButton = memo(
  ({
    template,
    favorites,
    disabled,
    isFullWidth = true,
  }: {
    template: CreatedAgent;
    favorites: TFavouriteElement[];
    disabled?: boolean;
    isFullWidth?: boolean;
  }) => {
    const { t } = useTranslation();
    const templateInFavourite = useMemo(() => {
      let currFavorite: TFavouriteElement | null = null;
      const { template: agentTemplate } = template;
      for (let favourite of favorites) {
        if (favourite.template !== agentTemplate) continue;
        if (isEqual(omit(favourite.params, 'seed'), omit(template.params, 'seed'))) {
          currFavorite = favourite;
          break;
        }
      }
      return currFavorite;
    }, [template, favorites]);

    const isTemplateInFavourite = useMemo(() => {
      return Boolean(templateInFavourite);
    }, [templateInFavourite]);

    const [isDeleting, setIsDeleting] = useState(false);

    const saveToFavoriteHandle = useCallback(
      async (name: string, description: string) => {
        if (isDemoMode.value) {
          return showSignupModal(true);
        }
        if (!template.id) return;
        return await toggleFavourite({
          appId: template.id,
          favorite: true,
          name,
          description,
        });
      },
      [template]
    );

    const deleteFromFavoriteHandle = useCallback(async () => {
      if (!template.id) return;
      setIsDeleting(true);
      try {
        await toggleFavourite({ appId: template.id, favorite: false });
      } finally {
        setIsDeleting(false);
        hideDeleteFavoriteModal();
      }
    }, [template.id]);

    return (
      <>
        <Button
          data-test-id='TemplateForm.Btn.addToFavorite'
          color='secondary'
          outline
          type='button'
          className={cn('gap-8', { 'w-full': isFullWidth, 'margin-top-8': isFullWidth })}
          disabled={disabled}
          onClick={
            Boolean(templateInFavourite)
              ? () => showDeleteFavoriteModal(templateInFavourite!.id)
              : setAddToFavoriteModalOpenTrue
          }
        >
          {t('AddToFavourite')}
          {isTemplateInFavourite ? (
            <Icon name='faStar' color='primary' />
          ) : (
            <Icon name='farStar' color='secondary' size='md' />
          )}
          <AddToFavouriteModal
            templateDefaultName={template.name || template.info.title}
            saveToFavorite={saveToFavoriteHandle}
            isOpen={isAddToFavoriteModalOpen.value}
          />
          {templateInFavourite ? (
            <DeleteFromFavouriteModal
              favoriteId={templateInFavourite.id}
              isDeleting={isDeleting}
              deleteFromFavoriteHandle={deleteFromFavoriteHandle}
              favoriteName={templateInFavourite?.name || template.info.title}
            />
          ) : null}
        </Button>
      </>
    );
  }
);
