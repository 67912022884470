import React, { FC, memo } from 'react';

import cn from 'classnames';

import { useSidebarActions } from '../../contexts/appContext';
import { ToggleSidebarButton } from '../Sidebar/components/OpenCloseButton';

export const SidebarToggler: FC<{}> = memo(({ children }) => {
  const [toggleSidebar, , isChatBarShown] = useSidebarActions();

  return (
    <div className='relative overflow-auto'>
      <div className={cn('padding-x-8 padding-y-16 absolute', { 'd-none': isChatBarShown })} style={{ zIndex: 50 }}>
        <ToggleSidebarButton
          onClick={toggleSidebar}
          icon='farArrowFromLeft'
          size='sm'
          side='left'
          className='padding-top-8 padding-bottom-8'
        />
      </div>
      {children}
    </div>
  );
});
