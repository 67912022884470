import React from 'react';

import { Button, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import { FieldConfig } from './useFormBuilder';
import { favourites } from '../../../../models/favourite/favourite.api';
import { templates } from '../../../../models/templates';
import { CreatedAgent } from '../../../../types/agents';
import { AddToFavoriteButton } from '../AddToFavoriteButton';

interface Props {
  template: CreatedAgent;
  onSubmitClick: () => void;
  onCancelClick: () => void;
  isSubmitDisabled?: boolean;
  isFullWidth?: boolean;
}

export default function TemplateFormButtons({
  template,
  onSubmitClick,
  onCancelClick,
  isSubmitDisabled = false,
  isFullWidth = true,
}: Props) {
  const { t } = useTranslation();

  const { templatesMap } = templates.value;

  return (
    <>
      <Button
        data-test-id='TemplateForm.Btn.cancel'
        color='secondary'
        outline
        type='button'
        onClick={onCancelClick}
        className={cn({ 'w-full': isFullWidth, 'd-sm-none': isFullWidth })}
      >
        {t('close')}
      </Button>

      {(template as CreatedAgent).id ? (
        <AddToFavoriteButton template={template} favorites={favourites.value} isFullWidth={isFullWidth} />
      ) : null}

      {!!(Object.values(templatesMap[template.template]?.params) as FieldConfig[]).filter(
        field => field.type !== 'file'
      ).length && (
        <Button
          id='TemplateFormSubmitBtn'
          data-test-id='TemplateForm.Btn.submit'
          color='primary'
          type='submit'
          onClick={onSubmitClick}
          className={cn({ 'w-full': isFullWidth, 'margin-top-8': isFullWidth })}
          disabled={isSubmitDisabled}
        >
          {t('Update')}
        </Button>
      )}
    </>
  );
}
