import React, { FC, memo, useState } from 'react';

import { useTranslation, DropdownButton, DropdownMenu, DropdownToggle, DropdownItem, Icon } from '@just-ai/just-ui';
import cn from 'classnames';

import { ContextInstruction } from './interface';
import styles from './style.module.scss';
import { InstructionsMessagePart } from '../../../types/chat';

const COLLAPSED_ELEMENTS_COUNT = 3;

interface InstructionsDropdownProps {
  contextInstructions: ContextInstruction[];
  sendInstructions: (agentInstructionsContent: InstructionsMessagePart) => void;
  messageId: string;
}

const InstructionsDropdown: FC<InstructionsDropdownProps> = memo(
  ({ messageId, contextInstructions, sendInstructions }) => {
    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation();

    const needDynamicInstructions = contextInstructions.length > COLLAPSED_ELEMENTS_COUNT + 1;
    const staticInstructions = needDynamicInstructions
      ? contextInstructions.slice(0, COLLAPSED_ELEMENTS_COUNT)
      : contextInstructions;
    const dynamicInstructions = needDynamicInstructions ? contextInstructions.slice(COLLAPSED_ELEMENTS_COUNT) : [];

    function renderInstructionItem(action: ContextInstruction) {
      return (
        <DropdownItem
          key={action.caption}
          data-test-id={`InstructionsDropdown.${action.caption}`}
          className={styles.dropdownMenu__Item}
          onClick={() =>
            sendInstructions({
              type: 'instruction',
              instructions: action.instructions.map(instruction => ({ ...instruction, source: [messageId] })),
            })
          }
        >
          {t(action.caption)}
        </DropdownItem>
      );
    }

    if (!contextInstructions.length) {
      return null;
    }

    return (
      <DropdownButton direction='down' onToggle={() => setExpanded(false)} data-test-id={`InstructionsDropdown.Button`}>
        <DropdownToggle color='none' tag='div' className={cn(styles.dropdownToggle)}>
          <Icon name='farSparkles' size='sm' className={cn(styles.dropdownToggle__Item)} />
          <span className={cn(styles.dropdownToggle__Item)}>{t('actionButtonsTrigger:do')}</span>
          <Icon name='farChevronDown' size='sm' className={cn(styles.dropdownToggle__Item)} />
        </DropdownToggle>
        <DropdownMenu
          key={expanded ? 'expanded' : 'collapsed'}
          className={cn('border-0', styles.dropdownMenu)}
          positionFixed={true}
          right={true}
          tag='div'
        >
          {staticInstructions.map(renderInstructionItem)}
          {dynamicInstructions.length > 0 ? (
            expanded ? (
              dynamicInstructions.map(renderInstructionItem)
            ) : (
              <>
                <DropdownItem divider={true} />
                <DropdownItem className={styles.dropdownMenu__Item} toggle={false} onClick={event => setExpanded(true)}>
                  {t('actionButtons:more')}
                </DropdownItem>
              </>
            )
          ) : null}
        </DropdownMenu>
      </DropdownButton>
    );
  }
);

InstructionsDropdown.displayName = 'ActionsDropdown';

export default InstructionsDropdown;
