var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AccountInvitationEndpointApi, AccountManagementEndpointApi, AccountsEndpointV2Api, AccountUserEndpointApi, RolesEndpointApi, UserEndpointApi, UserSearchEndpointApi, FeatureEndpointApi, ExternalSsoEndpointApi, RegistrationEndpointApi, AuthorizationEndpointApi, SmsCodeEndpointApi, AccountPaymentEndpointApi, OptionsEndpointApi, } from '../../generated/Accountsadmin';
class AccountsadminService {
    constructor(axios) {
        this.getAllowedAccounts = (userId, product) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.UserEndpointApi.getAllowedAccounts(userId, product)).data;
        });
        this.createAccount = (fullName, gRecaptchaResponse) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountsEndpointApi.createAccount({ fullName }, {
                headers: { 'g-recaptcha-response': gRecaptchaResponse },
            })).data;
        });
        this.getAccount = (accountId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountsEndpointApi.getAccount(accountId)).data;
        });
        this.updateAccount = (accountId_1, _a) => __awaiter(this, [accountId_1, _a], void 0, function* (accountId, { fullName, enabled }) {
            return (yield this.AccountsEndpointApi.updateAccount(accountId, {
                fullName,
                enabled,
            })).data;
        });
        this.inviteUser = (accountId_2, _b, gRecaptchaResponse_1) => __awaiter(this, [accountId_2, _b, gRecaptchaResponse_1], void 0, function* (accountId, { emails, domain, roles }, gRecaptchaResponse) {
            return (yield this.AccountInvitationEndpointApi.inviteUsers(accountId, { emails, domain, roles }, {
                headers: { 'g-recaptcha-response': gRecaptchaResponse },
            })).data;
        });
        this.getInvitationsByUser = (userId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountInvitationEndpointApi.getInvitationsByUser(userId)).data;
        });
        this.findAccountsByFilterAsSadmin = (filter) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountManagementEndpointApi.findAccountsByFilterAsSadmin(filter)).data;
        });
        this.acceptInvitationByUserId = (userId, accountId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountInvitationEndpointApi.acceptInvitationByUserId(userId, accountId)).data;
        });
        this.acceptInvitationByToken = (userId, token) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountInvitationEndpointApi.acceptInvitationByToken(userId, token)).data;
        });
        this.deleteUserFromAccount = (accountId, userId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountUserEndpointApi.deleteUserFromAccount(accountId, userId)).data;
        });
        this.deleteInvitation = (accountId, invitationId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountInvitationEndpointApi.deleteInvitation(accountId, invitationId)).data;
        });
        this.findAccountUsers = (_c) => __awaiter(this, [_c], void 0, function* ({ accountId, page, size, searchText, products, workplacesProduct, workplacesFitsLimit, }) {
            return (yield this.UserSearchEndpointApi.findAccountUsers(accountId, page, size, searchText, products, workplacesProduct, workplacesFitsLimit)).data;
        });
        this.findInvitations = (_d) => __awaiter(this, [_d], void 0, function* ({ accountId, page, size, searchText, }) {
            return (yield this.AccountInvitationEndpointApi.findByLoginOrFullNameInAccount(accountId, page, size, searchText))
                .data;
        });
        this.changeAccountRoles = (accountId, userId, roles) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountUserEndpointApi.changeAccountRoles(accountId, userId, {
                roles: roles,
            })).data;
        });
        this.changeInvitationRoles = (accountId, invitationId, roles) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountInvitationEndpointApi.changeInvitationRoles(accountId, invitationId, { roles: roles }))
                .data;
        });
        this.getRolesAvailableToAccount = (accountId) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.RolesEndpointApi.getRolesAvailableToAccount(accountId);
            return data.filter((role) => role.editable);
        });
        this.checkIsCaptchaNeeded = (userId, actionType) => __awaiter(this, void 0, void 0, function* () {
            return yield this.UserEndpointApi.isCaptchaNeeded(userId, actionType);
        });
        this.getAllProjectFeatures = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.FeatureEndpointApi.getAllProjectFeatures()).data;
        });
        this.getAllSystemFeatures = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.FeatureEndpointApi.getAllSystemFeatures()).data;
        });
        this.declineInvitation = (accountId, invitationId, userId) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AccountInvitationEndpointApi.declineInvitation(accountId, invitationId, userId);
        });
        this.checkEmailForSso = (userEmailDto) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.ExternalSsoEndpointApi.checkExternalSsoAvailabilityByEmail(userEmailDto)).data;
        });
        this.getExternalSsoUserForbiddenError = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.ExternalSsoEndpointApi.getExternalSsoUserForbiddenError()).data;
        });
        this.registerWithInvitation = (registerObj, captchaToken) => __awaiter(this, void 0, void 0, function* () {
            yield this.RegistrationEndpointApi.registerWithInvitation(registerObj, '', {
                headers: { 'g-recaptcha-response': captchaToken },
            });
        });
        this.getAccountUsersCapacity = (accountId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountsEndpointApi.getAccountCapacity(accountId)).data;
        });
        this.logout1 = () => this.AuthorizationEndpointApi.logout1();
        this.createThirdPartyToken = (codeChallenge) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AuthorizationEndpointApi.thirdPartyCreateToken({ codeChallenge })).data;
        });
        this.verifySmsProfile = (code, phone) => __awaiter(this, void 0, void 0, function* () {
            return yield this.SmsCodeEndpointApi.verifySmsCode1({ code, phone });
        });
        this.createPaymentSubscription = (accountId, requestBody) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AccountPaymentEndpointApi.createPaymentSubscription(accountId, requestBody);
        });
        this.getPaymentSubscription = (accountId) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AccountPaymentEndpointApi.getPaymentSubscription(accountId);
        });
        this.updatePaymentSubscription = (accountId, requestBody) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AccountPaymentEndpointApi.updatePaymentSubscription(accountId, requestBody);
        });
        this.deletePaymentSubscription = (accountId) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AccountPaymentEndpointApi.deletePaymentSubscription(accountId);
        });
        this.getPaymentStatusById = (accountId, paymentId, options) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AccountPaymentEndpointApi.getPaymentStatusById(accountId, paymentId, options);
        });
        this.checkIsUserAuthorized = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AuthorizationEndpointApi.checkIsUserAuthorized()).data;
        });
        this.getOptions = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.OptionsEndpointApi.getOptions()).data;
        });
        this.changeSelectedAccount = (selectedAccount) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AuthorizationEndpointApi.selectAccount(selectedAccount);
        });
        this.getTosAndPos = (userId, product) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.UserEndpointApi.getToSAndPoS(userId, product)).data;
        });
        this.updateProductsLimit = (accountId, updateData) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AccountManagementEndpointApi.updateAccountWorkplacesLimits(accountId, updateData);
        });
        this.getLimitsByProduct = (accountId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountsEndpointApi.getAccountWorkplacesLimits(accountId)).data;
        });
        const props = [{}, AccountsadminService.BASE_PATH, axios];
        this.UserEndpointApi = new UserEndpointApi(...props);
        this.AccountsEndpointApi = new AccountsEndpointV2Api(...props);
        this.UserSearchEndpointApi = new UserSearchEndpointApi(...props);
        this.AccountUserEndpointApi = new AccountUserEndpointApi(...props);
        this.RolesEndpointApi = new RolesEndpointApi(...props);
        this.FeatureEndpointApi = new FeatureEndpointApi(...props);
        this.AuthorizationEndpointApi = new AuthorizationEndpointApi(...props);
        this.ExternalSsoEndpointApi = new ExternalSsoEndpointApi(...props);
        this.RegistrationEndpointApi = new RegistrationEndpointApi(...props);
        this.AccountInvitationEndpointApi = new AccountInvitationEndpointApi(...props);
        this.AccountManagementEndpointApi = new AccountManagementEndpointApi(...props);
        this.SmsCodeEndpointApi = new SmsCodeEndpointApi(...props);
        this.AccountPaymentEndpointApi = new AccountPaymentEndpointApi(...props);
        this.OptionsEndpointApi = new OptionsEndpointApi(...props);
    }
}
AccountsadminService.BASE_PATH = '';
export default AccountsadminService;
