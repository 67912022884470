var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AppTemplatesApi, AppsApi, ConversationsApi, FilesApi, ResourcesApi, LinksApi, DynamicTemplatesApi, } from "../../generated/AppsAdapter";
class AppsAdapterService {
    constructor(axios) {
        this.appTemplatesApi = new AppTemplatesApi({}, AppsAdapterService.BASE_PATH, axios);
        this.appsApi = new AppsApi({}, AppsAdapterService.BASE_PATH, axios);
        this.filesApi = new FilesApi({}, AppsAdapterService.BASE_PATH, axios);
        this.resourcesApi = new ResourcesApi({}, AppsAdapterService.BASE_PATH, axios);
        this.linksApi = new LinksApi({}, AppsAdapterService.BASE_PATH, axios);
        this.conversationsApi = new ConversationsApi({}, AppsAdapterService.BASE_PATH, axios);
        this.telegramAppTemplatesApi = new AppTemplatesApi({}, `${AppsAdapterService.BASE_PATH}/direct`, axios);
        this.dynamicTemplatesApi = new DynamicTemplatesApi({}, AppsAdapterService.BASE_PATH, axios);
    }
    getTelegramTemplatesForLocale(locale) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.telegramAppTemplatesApi.findTemplates(locale === "eng" ? "en" : undefined, // Get Russian templates by default
            "telegram");
        });
    }
    findFavoriteApps() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.findFavoriteApps();
        });
    }
    changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options);
        });
    }
    createConversation(reqBody, async, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.createConversation(reqBody, "false", async, {
                abortController,
            });
        });
    }
    getTemplatesForLocale() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.appTemplatesApi.findTemplates();
        });
    }
    getUserAgents() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.findFavoriteApps();
        });
    }
    createUserAgent(config, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.createApp(config, "false", { abortController });
        });
    }
    prevalidateApp(config, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.prevalidateApp(config, { abortController });
        });
    }
    createUserConversation(reqBody, async, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.createConversation(reqBody, "false", async, {
                abortController,
            });
        });
    }
    getUserChats(params, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            const { hasUnreadChanges, orderBy, sort, match, sortBy, limit, skip } = params || {};
            return this.conversationsApi.findConversations(hasUnreadChanges, orderBy, sort, match, -1, sortBy, limit, skip, {
                abortController,
            });
        });
    }
    getUserChat(chatId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.findConversationById(chatId, {
                abortController,
            });
        });
    }
    clearUnreadChanges(conversationId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.clearUnreadChanges(conversationId, {
                abortController,
            });
        });
    }
    updateUserChatApp(chatId, updateData, async, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.updateConversationApp(chatId, updateData, async, {
                abortController,
            });
        });
    }
    deleteUserChat(chatId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.deleteConversationById(chatId, {
                abortController,
            });
        });
    }
    renameUserChat(chatId, newName, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.updateConversation(chatId, { name: newName }, {
                abortController,
            });
        });
    }
    sendMessageToChat(chatId, message, options, async) {
        return __awaiter(this, void 0, void 0, function* () {
            const { text, file, fileId, toolResponse, instructions } = message;
            return this.conversationsApi.sendMessage(chatId, async, file, fileId, text, instructions, toolResponse, options);
        });
    }
    deleteMessage(chatId, messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.deleteMessage(chatId, messageId);
        });
    }
    cancelMessageProcessing(chatId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.cancelMessageProcessing(chatId);
        });
    }
    getChatHistory(chatId, oldestMessageTimestamp, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.findConversationHistory(chatId, 20, oldestMessageTimestamp, options);
        });
    }
    clearChatHistory(chatId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.resetConversation(chatId, { abortController });
        });
    }
    uploadFile(file, conversationId, abortController, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.filesApi.uploadFile(conversationId, file, Object.assign({ abortController }, options));
        });
    }
    updateFile(fileId, content, abortController, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.filesApi.updateFile(fileId, { content }, Object.assign({ abortController }, options));
        });
    }
    deleteFileById(fileId, abortController, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.filesApi.deleteFileById(fileId, Object.assign({ abortController }, options));
        });
    }
    deleteByIds(fileIds, abortController, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.filesApi.deleteByIds(fileIds.join(","), Object.assign({ abortController }, options));
        });
    }
    //Links
    batchCreateLinks(urls, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.linksApi.batchCreateLinks({ urls }, { abortController });
        });
    }
    createLink(url, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.linksApi.createLink({ url }, { abortController });
        });
    }
    deleteLink(linkId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.linksApi.deleteLinkById(linkId, { abortController });
        });
    }
    apiAppsAdapterLinksDelete(linkIds, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.linksApi.apiAppsAdapterLinksDelete(linkIds.join(","), {
                abortController,
            });
        });
    }
    //Resources
    getResources(resourceSearchRequest, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.resourcesApi.searchResources(resourceSearchRequest, {
                abortController,
            });
        });
    }
    //External (Dynamic) Templates
    getDynamicTemplates() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.dynamicTemplatesApi.findDynamicTemplates();
        });
    }
    getDynamicTemplateById(templateId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.dynamicTemplatesApi.getDynamicTemplate(templateId);
        });
    }
    createDynamicTemplate(createReq) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.dynamicTemplatesApi.createDynamicTemplate(createReq);
        });
    }
    editDynamicTemplate(templateId, updateReq) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.dynamicTemplatesApi.updateDynamicTemplate(templateId, updateReq);
        });
    }
    deleteDynamicTemplate(templateId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.dynamicTemplatesApi.deleteDynamicTemplate(templateId);
        });
    }
    upscaleImage(conversationId, imageUrl, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.upscaleImage(conversationId, { imageUrl }, options);
        });
    }
    regenerateImage(conversationId, imageUrl, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.regenerateImage(conversationId, { imageUrl }, options);
        });
    }
}
AppsAdapterService.BASE_PATH = "";
export default AppsAdapterService;
