import { OptionsResponseDto, AccountInvitationDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { Community } from '@just-ai/api/dist/generated/CopilotGateway';
import { AppLogger } from '@just-ai/logger';
import { signal } from '@preact/signals-react';
import { AxiosError } from 'axios';

import apiClient from './client';
import { isDev } from '../utils/app/common';

type CC_response = {
  authentication: {
    authenticationType: 'CC' | 'NONE';
  };
  billingEnabled?: boolean;
  language: 'EN' | 'RU';
  demoHosts?: string[];
  appUrl?: string;
  systemFeatures?: string[];
  product?: {
    logo?: string;
    favicon?: string;
    name?: string;
    tos?: string;
    docs: {
      baseUrl: string;
      links: { [key: string]: string };
      queryParams: { [key: string]: string };
    };
    supportText?: string;
    communities?: Community[];
  };
};

export const appOptions = signal<CC_response | undefined>(undefined);
export const ccOptions = signal<OptionsResponseDto | undefined>(undefined);
export const ccInvitations = signal<AccountInvitationDto[] | undefined>(undefined);

export const getCCOptions = async () => {
  try {
    const { data } = await apiClient.get<OptionsResponseDto>('/api/accountsadmin/public/options');
    ccOptions.value = data;
  } catch (e) {
    if ((e as AxiosError).code) {
      AppLogger.error({
        message: `Error getting app options`,
        exception: e as AxiosError,
      });
    }
  }
};

export const getOptions = async (callbacks?: { callbackPositive?: () => void; callbackNegative?: () => void }) => {
  try {
    const { data } = await apiClient.get<CC_response>('/api/gateway/public/options');

    await getCCOptions();
    appOptions.value = { ...data };
    return data;
  } catch (e) {
    AppLogger.error({ message: 'Error getting cloud options', exception: e as Error });
  }
};

export const ccLogout = () => {
  window.location.href = isDev()
    ? `${window.location.protocol}//localhost:3001/c/logout`
    : `${window.location.origin}/c/logout?redirectUrl=${window.location.origin}`;
};

export const goToSelectAccount = () => {
  window.location.href = isDev()
    ? `https://localhost:3001/c/select-account`
    : `${window.location.origin}/c/select-account`;
};

export const appHasSystemFeature = (feature: string) => appOptions.value?.systemFeatures?.includes(feature);
