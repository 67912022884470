import React, { FC, memo, RefObject, useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@just-ai/just-ui';
import cn from 'classnames';

import 'animate.css';
import classes from './classes.module.scss';
import { toolAssistantButtonsLocalization } from './toolAssistantButtons.loc';
import localize, { t } from '../../../localization';

localize.addTranslations(toolAssistantButtonsLocalization);

enum ToolAssistantActions {
  createImage = 'createImage',
  createText = 'createText',
  think = 'think',
  find = 'find',
  writeCode = 'writeCode',
  giveAdvise = 'giveAdvise',
}

export const ToolAssistantActionButtons: FC<{ textareaRef?: RefObject<HTMLTextAreaElement>; sendMessage: () => void }> =
  memo(({ textareaRef, sendMessage }) => {
    const [selectedAction, setSelectedAction] = useState<ToolAssistantActions | null>(null);

    useEffect(() => {
      const innerTextareaRef = textareaRef?.current;
      const eventListener = event => {
        if (!textareaRef?.current?.value) {
          setSelectedAction(null);
        }
      };
      if (textareaRef?.current) textareaRef?.current.addEventListener('input', eventListener);

      return () => {
        innerTextareaRef && innerTextareaRef.removeEventListener('input', eventListener);
      };
    }, [textareaRef]);

    const onClickAction = (action: ToolAssistantActions, text: string, final?: boolean) => {
      setSelectedAction(action);
      if (textareaRef?.current) {
        textareaRef.current.value = text;
        textareaRef.current.focus();
      }
      if (final) {
        sendMessage();
      }
    };

    const buttonsActions = useMemo(() => {
      return [
        { type: ToolAssistantActions.createImage, icon: 'farImage' },
        { type: ToolAssistantActions.createText, icon: 'farFileAlt' },
        { type: ToolAssistantActions.think, icon: 'farLightbulb' },
        { type: ToolAssistantActions.find, icon: 'farSearch' },
        { type: ToolAssistantActions.writeCode, icon: 'farCode' },
        { type: ToolAssistantActions.giveAdvise, icon: 'farUserGraduate' },
      ];
    }, []);

    const segmentText = useCallback((text: string, segmented: string) => {
      return text.replace(segmented, `<span class="text-muted">${segmented}</span>`);
    }, []);

    if (selectedAction) {
      return (
        <div className={cn(classes.customToolAssistantActionsDropdown, 'd-flex flex-column w-100 margin-top-24')}>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <div
                className={cn(
                  classes.customToolAssistantActionsDropdown_item,
                  'd-flex w-100 padding-y-4 animate__animated animate__fadeInRight animate__fast'
                )}
                // @ts-ignore
                style={{ '--animate-delay': `${index * 100}ms` }}
                key={`${selectedAction}:text${index}`}
              >
                <div
                  className='w-100 cursor-pointer padding-x-12 padding-y-8 bg-hover-color-gray-100'
                  onClick={() => onClickAction(selectedAction, t(`${selectedAction}:text${index + 1}`), true)}
                  dangerouslySetInnerHTML={{
                    __html: segmentText(t(`${selectedAction}:text${index + 1}`), t(`${selectedAction}:button:text`)),
                  }}
                />
              </div>
            ))}
        </div>
      );
    }

    return (
      <div className='d-flex justify-content-center w-100 gap-8 margin-top-24 flex-wrap'>
        {buttonsActions.map(({ type, icon }) => (
          <Button
            key={icon}
            iconLeft={icon}
            color='secondary'
            flat
            outline
            onClick={() => onClickAction(type, t(`${type}:button:text`))}
          >
            {t(`${type}:button:text`)}
          </Button>
        ))}
      </div>
    );
  });
