import React, { useCallback, useEffect, useState } from 'react';

import {
  DynamicTemplateListResponseTemplates,
  DynamicTemplateType,
  DynamicTemplateUpsertRequest,
  DynamicTemplateUpsertRequestParams,
} from '@just-ai/api/dist/generated/AppsAdapter';
import { Modal, useTranslation, usePromiseProcessing, Spinner } from '@just-ai/just-ui';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { FormProvider, useForm } from 'react-hook-form';

import { t } from '../../../../localization';
import { addAlert } from '../../../../models/alerts';
import { appsAdapterService } from '../../../../services/service';
import FormBuilder from '../../../Agents/components/TemplateForm/FormBuilder';
import { FieldConfig } from '../../../Agents/components/TemplateForm/useFormBuilder';

export type DynamicTemplateModalProps = {
  type: 'create' | 'update' | 'delete';
  templateData?: DynamicTemplateListResponseTemplates;
  closeModal: () => void;
  updateCallback: () => Promise<void>;
  deleteLoading?: boolean;
};

const categories = ['external', 'marketing', 'legal', 'assistants', 'content', 'data'];

const getFormFields = (
  icon: string,
  authToken: string,
  title: string,
  description = '',
  category = 'external'
): FieldConfig[] => [
  {
    name: 'icon',
    type: 'emoji',
    required: true,
    value: icon,
  },
  {
    name: 'authToken',
    value: authToken,
    type: 'prompt',
    label: t('DynamicTemplate:AuthToken:Label'),
    required: true,
    hint: t('DynamicTemplate:AuthToken:Hint'),
  },
  {
    name: 'title',
    value: title,
    type: 'text',
    label: t('DynamicTemplate:Title:Label'),
    hint: t('DynamicTemplate:Title:Hint'),
    required: true,
    maxLength: 60,
  },
  {
    name: 'description',
    value: description,
    type: 'prompt',
    label: t('DynamicTemplate:Description:Label'),
    count: true,
    maxLength: 80,
  },
  {
    name: 'category',
    value: category,
    type: 'select',
    label: t('DynamicTemplate:Category:Label'),
    options: categories,
    required: true,
  },
];

export type TemplateDataForModal = DynamicTemplateUpsertRequestParams & {
  id?: string;
  title?: string;
  enabled?: boolean;
};

const defaultTemplate: TemplateDataForModal = { authToken: '', title: '', icon: '📖' };

export default function DynamicTemplateModal(props: DynamicTemplateModalProps) {
  const { closeModal, updateCallback, type, templateData: templateDataProp } = props;

  const { t } = useTranslation();

  const [templateData, setTemplateData] = useState<TemplateDataForModal | undefined>(defaultTemplate);
  const [fields, setFields] = useState<FieldConfig[]>([]);

  const [{ loading: getDataLoading }, getTemplateDataPromise] = usePromiseProcessing(async (templateId: string) =>
    appsAdapterService.getDynamicTemplateById(templateId)
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!templateDataProp?.id) return setFields(getFormFields('', '', '', 'external'));
      if (type === 'delete')
        return setTemplateData({
          id: templateDataProp.id,
          title: templateDataProp.title,
          enabled: templateDataProp.enabled,
        });
      const { data } = await getTemplateDataPromise(templateDataProp?.id);
      setTemplateData({ id: data.id, ...data.params, enabled: templateDataProp.enabled });
      return setFields(
        getFormFields(
          data.params?.icon || '',
          data.params?.authToken || '',
          data.params?.title || '',
          data.params?.description,
          data.params?.category
        )
      );
    };
    fetchData();
  }, [getTemplateDataPromise, templateDataProp?.enabled, templateDataProp?.id, templateDataProp?.title, type]);

  const methods = useForm({ reValidateMode: 'onChange' });

  const { handleSubmit, control, reset } = methods;

  const [{ loading: deleteLoading }, deleteTemplatePromise] = usePromiseProcessing(
    async (templateId: string) => appsAdapterService.deleteDynamicTemplate(templateId),
    {
      throwOnError: true,
    }
  );

  const [{ loading: createLoading }, createTemplatePromise] = usePromiseProcessing(
    async (createData: DynamicTemplateUpsertRequest) => appsAdapterService.createDynamicTemplate(createData),
    {
      onError: error => {
        if (axios.isAxiosError<{ error: string; errors: { field: string; problem: string }[] }>(error)) {
          if (error.response?.data.error === 'khub.proxy.unauthorized') {
            return methods.setError('authToken', { message: t(error.response?.data.error) });
          }
          if (error.response?.data.error === 'agentapi.validation.request_params') {
            error.response?.data?.errors?.forEach(err => {
              const fieldName = err.field?.split('.')[1];
              if (fieldName === 'authToken') methods.setError(fieldName, { message: t('khubTokenValError') });
            });
            return;
          }
          addAlert(t('createTemplateError'));
        }
      },
      throwOnError: true,
    }
  );

  const [{ loading: updateLoading }, updateTemplatePromise] = usePromiseProcessing(
    async (templateId: string, createData: DynamicTemplateUpsertRequest) =>
      appsAdapterService.editDynamicTemplate(templateId, createData),
    {
      onError: error => {
        if (axios.isAxiosError<{ error: string; errors: { field: string; problem: string }[] }>(error)) {
          if (error.response?.data.error === 'khub.proxy.unauthorized') {
            return methods.setError('authToken', { message: t(error.response?.data.error) });
          }
          if (error.response?.data.error === 'agentapi.validation.request_params') {
            error.response?.data?.errors?.forEach(err => {
              const fieldName = err.field?.split('.')[1];
              if (fieldName === 'authToken') methods.setError(fieldName, { message: t('khubTokenValError') });
            });
            return;
          }
          addAlert(t('updateTemplateError'));
        }
      },
      throwOnError: true,
    }
  );

  const createTemplateHandle = useCallback(
    async (formValues?: DynamicTemplateUpsertRequestParams) => {
      if (!formValues) return;
      const sanitizedValues = Object.entries(formValues).reduce((accValue, currValue) => {
        accValue[currValue[0]] = DOMPurify.sanitize(currValue[1]);
        return accValue;
      }, {} as DynamicTemplateUpsertRequestParams);
      await createTemplatePromise({
        type: DynamicTemplateType.KhubProxy,
        params: { ...sanitizedValues },
        enabled: false,
      });
      closeModal();
      await updateCallback();
      reset({});
    },
    [createTemplatePromise, updateCallback, reset, closeModal]
  );

  const create = useCallback(() => {
    handleSubmit(createTemplateHandle)();
  }, [handleSubmit, createTemplateHandle]);

  const updateTemplateHandle = useCallback(
    async (formValues?: DynamicTemplateUpsertRequestParams) => {
      if (!templateData?.id || !formValues) return;
      const sanitizedValues = Object.entries(formValues).reduce((accValue, currValue) => {
        accValue[currValue[0]] = DOMPurify.sanitize(currValue[1]);
        return accValue;
      }, {} as DynamicTemplateUpsertRequestParams);
      try {
        await updateTemplatePromise(templateData.id, {
          type: DynamicTemplateType.KhubProxy,
          params: { ...sanitizedValues },
          enabled: templateData.enabled,
        });
        closeModal();
        await updateCallback();
        reset({});
      } catch (error) {}
    },
    [templateData?.id, templateData?.enabled, updateTemplatePromise, closeModal, updateCallback, reset]
  );

  const update = useCallback(() => {
    handleSubmit(updateTemplateHandle)();
  }, [handleSubmit, updateTemplateHandle]);

  const handleTemplateDelete = async () => {
    if (templateData?.id) await deleteTemplatePromise(templateData.id);
    updateCallback();
    closeModal();
  };

  if (type === 'delete')
    return (
      <Modal
        title={t(`Account:DTemplate:Modal:titleDel`)}
        isOpen
        onCancelClick={closeModal}
        inProgress={deleteLoading}
        buttonCancelText={t('cancel')}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonSubmitColor='danger'
        buttonSubmitText={t('delete')}
        onActionClick={handleTemplateDelete}
        buttonSubmitTestId='DtemplateModalDeleteSubmit'
        buttonCancelTestId='DtemplateModalDeleteCancel'
        size='md'
      >
        {t('Account:DTemplate:Modal:infoDel', { name: templateData?.title })}
      </Modal>
    );

  return (
    <Modal
      title={t(`Account:DTemplate:Modal:titleCreate`)}
      isOpen
      onCancelClick={closeModal}
      onActionClick={type === 'create' ? create : update}
      buttonSubmitText={type === 'create' ? t('Account:dTemplates:create') : t('save')}
      inProgress={getDataLoading || type === 'create' ? createLoading : updateLoading}
      buttonCancelText={t('close')}
      buttonCancelColor='secondary'
      size='md'
      buttonSubmitTestId='DtemplateModalCreateSubmit'
      buttonCancelTestId='DtemplateModalCreateCancel'
    >
      {/* inline style to prevent modal height change on exit  */}
      <div className='flex flex-col gap-24' style={{ minHeight: '498px' }}>
        {getDataLoading ? (
          <Spinner size='4x' />
        ) : (
          <FormProvider {...methods}>
            {fields.map(field => (
              <FormBuilder
                key={field.name}
                control={control}
                isEdit={type === 'update'}
                data={{}}
                param={field}
                handleFileUpload={() => Promise.resolve(undefined)}
              />
            ))}
          </FormProvider>
        )}
      </div>
    </Modal>
  );
}
