export const toolAssistantButtonsLocalization = {
  'createImage:text1': {
    eng: '',
    ru: 'Создай изображение для моей презентации',
  },
  'createImage:text2': {
    eng: '',
    ru: 'Создай изображение для сайта',
  },
  'createImage:text3': {
    eng: '',
    ru: 'Создай изображение товара',
  },
  'createText:text1': {
    eng: '',
    ru: 'Напиши текст для слайда',
  },
  'createText:text2': {
    eng: '',
    ru: 'Напиши текст для ответа в переписке',
  },
  'createText:text3': {
    eng: '',
    ru: 'Напиши текст для блога',
  },
  'think:text1': {
    eng: '',
    ru: 'Придумай несколько идей для рекламной кампании',
  },
  'think:text2': {
    eng: '',
    ru: 'Придумай план проекта',
  },
  'think:text3': {
    eng: '',
    ru: 'Придумай пост в блог',
  },
  'find:text1': {
    eng: '',
    ru: 'Найди конкурентов и дай по ним анализ ',
  },
  'find:text2': {
    eng: '',
    ru: 'Найди данные о товаре',
  },
  'find:text3': {
    eng: '',
    ru: 'Найди закономерности в данных',
  },
  'writeCode:text1': {
    eng: '',
    ru: 'Помоги мне отладить код',
  },
  'writeCode:text2': {
    eng: '',
    ru: 'Помоги написать функцию',
  },
  'writeCode:text3': {
    eng: '',
    ru: 'Помоги написать тест-кейс',
  },
  'giveAdvise:text1': {
    eng: '',
    ru: 'Дай совет как работать с генеративным ИИ',
  },
  'giveAdvise:text2': {
    eng: '',
    ru: 'Дай совет как начать сложную задачу',
  },
  'giveAdvise:text3': {
    eng: '',
    ru: 'Дай совет как научиться выступать публично',
  },
  'createImage:button:text': {
    eng: '',
    ru: 'Создай изображение',
  },
  'createText:button:text': {
    eng: '',
    ru: 'Напиши текст',
  },
  'think:button:text': {
    eng: '',
    ru: 'Придумай',
  },
  'find:button:text': {
    eng: '',
    ru: 'Найди',
  },
  'writeCode:button:text': {
    eng: '',
    ru: 'Напиши код',
  },
  'giveAdvise:button:text': {
    eng: '',
    ru: 'Дай совет',
  },
  '': {
    eng: '',
    ru: '',
  },
  '': {
    eng: '',
    ru: '',
  },
  '': {
    eng: '',
    ru: '',
  },
};
