import { ConversationResponse, MessageResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { computed } from '@preact/signals-react';
import { format } from 'date-fns';

import { deleteConversation } from './apiHooks';
import {
  conversationsSg,
  newConversationLoading,
  setConversationsValue,
  allConversationsSg,
  conversationsInvalidatedSg,
} from './signals';
import { appsAdapterService } from '../../services/service';
import { AgentType } from '../../types/agents';
import {
  ChatRequest,
  Conversation,
  isMessageFile,
  isMessageInstructions,
  isMessageLink,
  isMessageText,
  isMessageToolResponse,
  Message,
} from '../../types/chat';
import { KeyValuePair } from '../../types/data';
import { processConversationContext, processHistory } from '../../utils/app/conversation';

export const buildingRequests = computed(() => {
  return allConversationsSg.value.filter(
    conversation => conversation.status === 'BUILDING' || conversation.messageIsStreaming
  );
});

export const isConversationBuilding = (conversationId?: string) => {
  if (!conversationId) return false;
  return buildingRequests.value.findIndex(buildingConversation => buildingConversation.id === conversationId) > -1;
};

export const isConversationStreaming = (conversationId?: string) => {
  if (!conversationId) return false;
  const conversation = buildingRequests.value.find(buildingConversation => buildingConversation.id === conversationId);
  return conversation?.messageIsStreaming ?? false;
};

export const isRequestsLimitExceeded = computed(() => buildingRequests.value.length >= 3);

export const dropConversations = () => {
  conversationsSg.value = [];
};

export const handleDeleteConversation = async (conversationId: Conversation['id']) => {
  await deleteConversation(conversationId);
  setConversationsValue(allConversationsSg.value.filter(c => c.id !== conversationId));
  conversationsInvalidatedSg.value = true;
};

export const findConversationIndex = (conversationId: string) => {
  return allConversationsSg.value.findIndex(convers => convers.id === conversationId);
};
export const findConversation = (conversationId: string) => {
  return allConversationsSg.value.find(convers => convers.id === conversationId);
};

export const handleUpdateConversation = (conversation: Conversation, data: KeyValuePair | KeyValuePair[]) => {
  let updatedConversation = { ...conversation };

  if (Array.isArray(data)) {
    data.forEach(keyValuePair => {
      updatedConversation = {
        ...updatedConversation,
        [keyValuePair.key]: keyValuePair.value,
      };
    });
  } else {
    updatedConversation = {
      ...updatedConversation,
      [data.key]: data.value,
    };
  }

  setConversationsValue(conversations => {
    let conversationForUpdateIndex = findConversationIndex(conversation.id);
    if (conversationForUpdateIndex > -1) {
      conversations[conversationForUpdateIndex] = updatedConversation;
    }
  });
};

export const handleDeleteMessage = (conversationId: string, messageId: string) => {
  const conversation = findConversation(conversationId);
  if (conversation) {
    handleUpdateConversation(conversation, {
      key: 'history',
      value: conversation.history.filter(message => message.id !== messageId),
    });
  }
};

export const saveNewTitle = async ({ id, title }: { id: string; title: string }) => {
  const { data } = await appsAdapterService.renameUserChat(id, title);
  setConversationsValue(conversations => {
    const updateIndex = findConversationIndex(id);
    if (updateIndex > -1 && conversations[updateIndex].name) {
      conversations[updateIndex].name = title;
    }
  });
  return data;
};

/**
 * Downloads a file to resend it to the backend.
 * This is only relevant when regenerating a message containing a file.
 * In this case the frontend only has the file link to work with, not having access to the file binary.
 * Left for backwards compatibility only.
 * @deprecated
 * @param link Link to the file.
 * @returns Downloaded file object.
 */
const downloadFile = async (link: string): Promise<File> => {
  const file = await fetch(link, { headers: { 'Content-Type': 'multipart/form-data' } });
  const fileNameMatch = file.headers.get('Content-Disposition')?.match(/attachment; filename="(?<fileName>.+?)"/);
  const fileName = fileNameMatch ? decodeURIComponent(fileNameMatch.groups!.fileName) : 'unknown';
  return new File([await file.blob()], fileName);
};

export const sendMessageToChat = async (
  chatId: string,
  messageContent: Message['content'],
  abortController?: AbortController
) => {
  const messageFile = messageContent.find(isMessageFile)?.file;
  const { url: messageLink, fileId } = messageContent.find(isMessageLink) ?? {};
  const messageText = messageContent.find(isMessageText)?.text;
  const messageToolResponse = messageContent.find(isMessageToolResponse);
  const messageInstructions = messageContent.find(isMessageInstructions);
  const headers = messageFile ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' };
  const body: ChatRequest = { text: messageText ?? '' };

  if (messageText && messageLink) {
    Object.assign(body, fileId ? { fileId } : { file: await downloadFile(messageLink) });
  } else if (messageLink) {
    body.text = messageLink;
  }
  if (messageFile) {
    body.file = messageFile;
  }
  if (messageToolResponse) {
    body.toolResponse = JSON.stringify(messageToolResponse.toolResponse);
  }
  if (messageInstructions) {
    body.instructions = JSON.stringify(messageInstructions.instructions);
  }

  const { data, headers: responseHeaders } = await appsAdapterService.sendMessageToChat(
    chatId,
    body,
    {
      headers,
      signal: abortController && abortController.signal,
    },
    true
  );
  // if async as now response is meta | otherwise MessageResponse
  return { data: data as MessageResponse | { meta: { isProcessing: boolean } }, headers: responseHeaders };
};

export const handleNewConversation = async (
  rawConversationResponse: ConversationResponse,
  templateSource: AgentType
) => {
  const history: Message[] = processHistory(rawConversationResponse.history);

  const contextValue = processConversationContext(history);

  const newConversation: Conversation = {
    ...rawConversationResponse,
    id: rawConversationResponse.id,
    name: rawConversationResponse.name || `Assistant - ${format(new Date(), 'HH:mm dd.MM')}`,
    history,
    config: templateSource,
    contextValue,
  };
  conversationsSg.value = [newConversation, ...conversationsSg.value];
  newConversationLoading.value = false;
  return newConversation;
};

export * from './effects';
