import React, { memo } from 'react';

import { Button, Modal, useToggle, useTranslation } from '@just-ai/just-ui';

import { ccLogout } from '../../api/cc.api';
import { setUserToken } from '../../api/client';
import { dropAgents } from '../../models/agents';
import { dropConversations } from '../../models/conversations';
import { goToLogin } from '../../routes';

const AccountLogOut = memo(() => {
  const { t } = useTranslation();

  const [isModalOpen, openModal, closeModal] = useToggle();

  const localLogout = async () => {
    closeModal();
    dropConversations();
    dropAgents();
    localStorage.removeItem('token');
    setUserToken('');
    ccLogout();
    return;
  };

  return (
    <>
      <Button
        color='secondary'
        outline
        className='w-full'
        iconLeft='farSignOut'
        onClick={openModal}
        data-test-id='Logout.Btn'
      >
        {t('logoutVerb')}
      </Button>

      <Modal
        title={t('logoutTitle')}
        onActionClick={localLogout}
        isOpen={isModalOpen}
        buttonCancelText={t('cancel')}
        buttonSubmitText={t('logoutVerb')}
        onCancelClick={closeModal}
        buttonSubmitTestId='Logout.Modal.Accept'
        buttonCancelTestId='Logout.Modal.Cancel'
      >
        {t('logoutMsg')}
      </Modal>
    </>
  );
});

export default AccountLogOut;
