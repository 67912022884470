import React, { FC, memo, useCallback } from 'react';

import { IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './styles.module.scss';
import { CanvasInstance, useCanvasHolder } from '../CanvasHolderContext/CanvasHolderContext';

export const CanvasHolderTab: FC<{ canvasInstance: CanvasInstance }> = memo(({ canvasInstance }) => {
  const { closeCanvasInstance, setActiveTab } = useCanvasHolder();

  const setActiveTabHandle = useCallback(() => {
    setActiveTab(canvasInstance.instanceId);
  }, [setActiveTab, canvasInstance.instanceId]);

  return (
    <div
      className={cn(styles.canvasHolder_Tabs__tab, {
        [styles.canvasHolder_Tabs__tab__activeTab]: canvasInstance.isActive || false,
      })}
      onClick={setActiveTabHandle}
    >
      <span className={cn(styles.canvasHolder_Tabs__tab__name)}>{canvasInstance.title}</span>
      <IconButton
        name='faTimes'
        size='md'
        flat
        withoutPadding
        className='color-gray-600'
        onClick={e => {
          e.stopPropagation();
          closeCanvasInstance(canvasInstance.instanceId);
        }}
      />
    </div>
  );
});
CanvasHolderTab.displayName = 'memo(CanvasHolderTab)';
