import { ConversationResponse, MessageResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { format } from 'date-fns';
import { uniqueId } from 'lodash';
import Mustache from 'mustache';

import { defaultContextValue } from '../../models/conversations/signals';
import { templates } from '../../models/templates';
import { AgentType, CreatedAgent } from '../../types/agents';
import { Conversation, Message, MessageContent } from '../../types/chat';

export const MAIN_TEMPLATE_NAME = 'toolAssistant';

export const updateConversation = (updatedConversation: Conversation, allConversations: Conversation[]) => {
  const updatedConversations = allConversations.map(c => {
    if (c.id === updatedConversation.id) {
      return updatedConversation;
    }

    return c;
  });

  return {
    single: updatedConversation,
    all: updatedConversations,
  };
};

export const createFirstMessage = (message: string, data: Record<string, any>) => {
  const dataCopy = { ...data };
  Mustache.tags = ['{', '}'];
  return Mustache.render(message, dataCopy);
};

export const processHistory = (
  rawChatHistory: ConversationResponse['history'] | MessageResponse[] = []
): Conversation['history'] => {
  return rawChatHistory.map(rawHistoryItem => ({
    ...rawHistoryItem,
    status: rawHistoryItem.status as Message['status'],
    content: rawHistoryItem.content as MessageContent,
  }));
};

export const createDefaultMsg = (content: MessageContent, conversationId: string, type: Message['type']): Message => ({
  content,
  conversationId,
  type,
  createdAt: Date.now(),
  updatedAt: Date.now(),
  status: 'success',
  id: uniqueId(),
});

export const processConversationContext = (data?: Conversation['history']) => {
  let conversationContextData: Conversation['contextValue'] = defaultContextValue;
  if (!data) return conversationContextData;

  const lastMessage = data.at(-1);
  const secondLastMessage = data.at(-2);
  if (
    !lastMessage ||
    (lastMessage?.type === 'system' &&
      (lastMessage?.content[0]?.type === 'systemAppUpdate' ||
        (secondLastMessage?.type === 'system' && secondLastMessage?.content[0]?.type === 'systemAppUpdate')))
  ) {
    return conversationContextData;
  }

  const lastMessageResponse = [...data].reverse().find(msg => msg.type === 'response');
  const usageData = lastMessageResponse?.tokensUsage;
  if (!lastMessageResponse || !usageData) return conversationContextData;

  let isContextOverflowed = false;
  if (usageData.usedContext > 0) {
    const contextUsageRatio = usageData.usedContext;
    if (contextUsageRatio > 100) {
      isContextOverflowed = true;
    }
    conversationContextData = {
      ...conversationContextData,
      fullness: isContextOverflowed ? 100 : Math.round(contextUsageRatio),
    };
  }
  if (usageData.isMessagesTruncated || isContextOverflowed) {
    conversationContextData = { ...conversationContextData, messagesTruncationMode: true };
  }
  return conversationContextData;
};

export const isConversationTemplateValid = (template: AgentType | CreatedAgent) => {
  return (!!template?.info && template.categories.length > 0) || template.template === MAIN_TEMPLATE_NAME;
};

export const generateDefaultName = (template: string) => {
  const templateTitle = templates.value.templatesMap?.[template]?.info?.title;
  return templateTitle ? `${templateTitle} - ${format(new Date(), 'HH:mm dd.MM')}` : undefined;
};
