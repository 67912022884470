import React, { ReactNode, useCallback } from 'react';

import { Button, IconButton } from '@just-ai/just-ui';
import cn from 'classnames';
import { mainConversationSg } from 'models/conversations/signals';
import { availableAccountsToChange } from 'models/currentUser';

import { ToggleSidebarButton } from './components/OpenCloseButton';
import { SelectAccountButton } from './components/SelectAccountButton';
import { LogoComponent } from './LogoComponent';
import styles from './style.module.scss';
import { useAppContext, useSidebarActions } from '../../contexts/appContext';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import { goToTemplates } from '../../routes';
import { Conversation } from '../../types/chat';
import { isMobile } from '../../utils/app/common';
import MainConversation from '../Chatbar/components/MainConversation';
import { hideAgentSettingsMobileToggle } from '../Settings/signals';

const Sidebar = ({
  isOpen,
  addItemButtonTitle,
  side,
  itemComponent,
  footerComponent,
  toggleOpen,
  onMainConversationClick: handleMainConversationClick,
}: Props) => {
  const {
    state: { lightMode },
  } = useAppContext();
  const [toggleSidebar] = useSidebarActions();

  const openAssistantCreationModal = useCallback(() => {
    guideTourEvent$.next('GoToMainFromSidebarButton');
    if (isMobile()) {
      hideAgentSettingsMobileToggle();
      toggleSidebar();
    }
    goToTemplates();
  }, [toggleSidebar]);

  const mainConversation = mainConversationSg.value;
  const availableAccounts = availableAccountsToChange();

  return (
    <div
      className={cn(styles.sidebar, {
        [styles.sideBarOpen]: isOpen,
      })}
    >
      <div className={cn('flex align-items-center justify-between gap-16', styles.sidebar__header)}>
        <div className='flex gap-16 w-100'>
          <div className='flex d-sm-none'>
            <ToggleSidebarButton onClick={toggleOpen} side={side} className='padding-top-8 padding-bottom-8' />
          </div>
          <div className={cn('flex justify-between', { 'w-100': !isMobile() })}>
            <LogoComponent />
            {!isMobile() && (
              <ToggleSidebarButton
                onClick={toggleOpen}
                side='right'
                size='sm'
                icon='farArrowToLeft'
                className='padding-top-8 padding-bottom-8'
              />
            )}
          </div>
        </div>
      </div>
      <div className={cn(`${lightMode}`, styles.sidebar__wrapper)}>
        {availableAccounts && availableAccounts.length > 1 && <SelectAccountButton />}
        <div className='flex items-center padding-bottom-0x padding-right-16 padding-top-16 padding-left-16'>
          <Button
            className={cn('flex w-full user-select-none', styles.sidebar__btnCreate)}
            onClick={openAssistantCreationModal}
            iconRight='farBars'
            color='primary'
            size='xl'
            data-test-id='GoToChooseNewTemplate'
          >
            {addItemButtonTitle}
          </Button>
        </div>
        {mainConversation ? (
          <div className='flex items-center padding-bottom-0x padding-right-16 padding-top-16 padding-left-16'>
            <MainConversation
              key={mainConversation.id}
              conversation={mainConversation}
              onClick={handleMainConversationClick}
            />
          </div>
        ) : null}
        {itemComponent}
        {footerComponent}
      </div>
    </div>
  );
};

interface Props {
  isOpen: boolean;
  addItemButtonTitle: string;
  side: 'left' | 'right';
  itemComponent: ReactNode;
  footerComponent?: ReactNode;
  toggleOpen: () => void;
  onMainConversationClick: (conversation: Conversation) => void;
}

export default Sidebar;
