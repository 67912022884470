import { Element } from 'hast';
import { ElementContent, Root, RootContent } from 'hast';
import { whitespace as isWhitespaceNode } from 'hast-util-whitespace';
import { visit } from 'unist-util-visit';

const isBlankNode = (node: RootContent | ElementContent) => {
  return (node.type === 'element' && node.tagName === 'br') || isWhitespaceNode(node);
};

const isImageNode = (node: RootContent | ElementContent): node is Element => {
  return node.type === 'element' && node.tagName === 'img';
};

const rehypeGroupImagesToFigures = () => {
  return (tree: Root) => {
    visit(tree, 'element', (node, index, parent) => {
      if (!isImageNode(node) || index === undefined || parent === undefined) {
        return;
      }

      const imageNodes: ElementContent[] = [];
      let currentIndex = index;

      while (currentIndex < parent.children.length) {
        const currentNode = parent.children[currentIndex];
        if (isImageNode(currentNode)) {
          imageNodes.push({ ...currentNode });
          currentIndex++;
        } else if (isBlankNode(currentNode)) {
          currentIndex++;
        } else {
          break;
        }
      }

      // Replace the range of `img` nodes with the new `figure` node.
      parent.children.splice(index, currentIndex - index, {
        type: 'element',
        tagName: 'figure',
        properties: {},
        children: imageNodes,
      });

      // Skip the newly inserted figure node.
      return index + 1;
    });
  };
};

export default rehypeGroupImagesToFigures;
