import React, { FC, memo, useRef } from 'react';

import { Icon, ICONS } from '@just-ai/just-ui';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';
import ImageGallery from '../../components/Chat/ImageGallery/ImageGallery';

const mapMimeTypeToIcon: Record<string, ICONS> = {
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'farFilePowerpoint',
  'application/vnd.ms-powerpoint': 'farFilePowerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'farFileWord',
  'application/msword': 'farFileWord',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'farFileExcel',
  'application/vnd.ms-excel': 'farFileExcel',
  'application/pdf': 'farFilePdf',
  'plain/text': 'farFileAlt',
  'application/xml': 'farFileAlt',
  'text/xml': 'farFileAlt',
};
const keys = Object.keys(mapMimeTypeToIcon);

export const FilterRowIcon: FC<{ mimeType?: string; publicUrl?: string; favicon?: string }> = memo(
  ({ mimeType, publicUrl, favicon }) => {
    const id = useRef(uuidv4());
    if (favicon) {
      return (
        <div className={cn(styles.mimeContainer)}>
          <img className={cn(styles.favicon)} src={favicon} alt='link favicon' />
        </div>
      );
    }
    if (!mimeType) return null;
    if (mimeType.startsWith('image') && publicUrl)
      return (
        <div className={cn(styles.mimeImageContainer)}>
          <ImageGallery galleryId={id.current} images={[{ thumbnailURL: publicUrl, largeURL: publicUrl }]} />
        </div>
      );
    if (keys.includes(mimeType))
      return (
        <div className={cn(styles.mimeContainer)}>
          <Icon name={mapMimeTypeToIcon[mimeType]} className={cn(styles.mimeContainer_icon)} size='lg' />
        </div>
      );
    return (
      <div className={cn(styles.mimeContainer)}>
        <Icon name={mapMimeTypeToIcon['plain/text']} className={cn(styles.mimeContainer_icon)} size='lg' />
      </div>
    );
  }
);
