import React from 'react';

import { Icon, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import filesPageStyles from '../../../../../../pages/Files/styles.module.scss';
import { Conversation } from '../../../../../../types/chat';

type Props = {
  onClick: () => void;
  selectedConversation: Conversation;
};

export default function ChatHeaderUpdateAppSettingsButton({ selectedConversation, onClick: handleClick }: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={cn(filesPageStyles.dropdownMenu__Item, 'text-nowrap', 'mt-3', {
        'cursor-not-allowed': selectedConversation.messageIsStreaming,
      })}
      data-test-id='Chat.btn_update_app_settings'
      onClick={handleClick}
    >
      <Icon name='farSlidersV' color='secondary' size='md' />
      {t('settings')}
    </div>
  );
}
