import React, { FC, memo } from 'react';

import { IconButton, IconButtonProps, Tooltip } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from '../style.module.scss';

export const ImageGalleryButton: FC<{
  disabled: boolean;
  id: string;
  tooltipText: string;
  icon: IconButtonProps['name'];
  dataTestId?: string;
  onClick?: () => unknown;
}> = memo(({ disabled, tooltipText, icon, id, dataTestId, onClick }) => {
  return (
    <>
      <IconButton
        name={icon}
        size='sm'
        className={cn(styles.chat__showUiButton)}
        disabled={disabled}
        outline={true}
        flat
        color='secondary'
        data-test-id={dataTestId}
        tooltipWrapperId={id}
        onClick={onClick}
      />
      <Tooltip placement='top' target={id}>
        {tooltipText}
      </Tooltip>
    </>
  );
});

ImageGalleryButton.displayName = 'ImageGalleryButton';
