import { FC, memo, useCallback, useEffect, useMemo } from 'react';

import { setConversationStatus } from '../../../models/conversations/signals';
import { ToolCallMessagePart, type ToolResponseItem } from '../../../types/chat';
import { useCanvasHolder } from '../CanvasHolderContext/CanvasHolderContext';
import { useShowUiToolResponses } from '../useShowUiToolResponses.hook';

export const ToolCallMessage: FC<{ content: ToolCallMessagePart['toolCalls']; selectedConversationId: string }> = memo(
  ({ content, selectedConversationId }) => {
    const showUiCalls = useMemo(
      () => content.filter(contentItem => contentItem.function.name === 'show_ui'),
      [content]
    );
    const sendFileToIframeMsgs = useMemo(
      () => content.filter(contentItem => contentItem.function.name === 'setFile'),
      [content]
    );

    const { toolCalls, sendToolResponseMessageToChat, setFileInIframe } = useCanvasHolder();

    const getShowUiToolResponses = useShowUiToolResponses(showUiCalls, selectedConversationId);

    const showUi = useCallback(async () => {
      const { toolResponses } = await getShowUiToolResponses();
      if (toolResponses.length > 0) return toolResponses;
      return [];
    }, [getShowUiToolResponses]);

    const setFileInIframeHandler = useCallback(async (): Promise<ToolResponseItem[]> => {
      if (!sendFileToIframeMsgs.length) return [];
      let results: ToolResponseItem[] = [];
      for (let sendFileToIframeMsg of sendFileToIframeMsgs) {
        try {
          const parsedArgs = JSON.parse(sendFileToIframeMsg.function.arguments);
          const { uri, type, name } = JSON.parse(sendFileToIframeMsg.function.arguments);
          const promiseResults = await setFileInIframe(
            { uri, type, name },
            sendFileToIframeMsg,
            parsedArgs.externalInstanceId
          );
          results = [...promiseResults, ...results];
        } catch (e) {}
      }
      return results;
    }, [sendFileToIframeMsgs, setFileInIframe]);
    //force set status to "building", to avoid user write in chat before iframe response
    const setConversationStatusBuilding = useCallback(() => {
      setConversationStatus(selectedConversationId);
    }, [selectedConversationId]);

    const precessTools = useCallback(async () => {
      setConversationStatusBuilding();
      const PromiseArray: Promise<ToolResponseItem[]>[] = [];
      if (showUiCalls.length) PromiseArray.push(showUi());
      if (sendFileToIframeMsgs.length) PromiseArray.push(setFileInIframeHandler());
      const callsWithoutSpecial = content.filter(
        contentItem => !['show_ui', 'setFile'].includes(contentItem.function.name)
      );
      if (callsWithoutSpecial.length > 0) {
        PromiseArray.push(toolCalls(callsWithoutSpecial));
      }
      return Promise.all(PromiseArray);
    }, [
      content,
      sendFileToIframeMsgs.length,
      setConversationStatusBuilding,
      setFileInIframeHandler,
      showUi,
      showUiCalls.length,
      toolCalls,
    ]);

    useEffect(() => {
      precessTools().then(resolvedPromises => {
        sendToolResponseMessageToChat(resolvedPromises.flat());
      });
    }, [precessTools, sendToolResponseMessageToChat]);

    return null;
  }
);
