import React, { FC, memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import { omit } from 'lodash';

import { useIframeRegistration } from './iFrameRegistration.hook';
import apiClient from '../../../api/client';
import localize from '../../../localization';
import { findConversationIndex } from '../../../models/conversations';
import { streamMessage } from '../../../models/conversations/apiHooks';
import { dropSilentMessages } from '../../../models/conversations/canvas/silentMessage.signal';
import { dropFilesToUpload } from '../../../models/conversations/canvas/uploadFiles.signal';
import { allConversationsSg, setConversationsValue } from '../../../models/conversations/signals';
import { externalApps } from '../../../models/externalApps/signals';
import useApiService from '../../../services/useApiService';
import type { ToolCallItem, ToolCallMessagePart, ToolResponseItem } from '../../../types/chat';
import { ToolEventTransport, ToolRequest } from '../../../utils/iFrameEventBus';

export type CanvasInstance = {
  instanceId: string;
  url: string;
  title: string;
  onLoad?: () => Promise<void>;
  isActive?: boolean;
};

const TCanvasHolderContext = React.createContext<{
  registerIframe: (iframe: HTMLIFrameElement, instanceId: string) => void;
  unregisterIframe: (instanceId: string) => void;
  setFileInIframe: (
    { uri, type, name }: { uri: string; type: string; name: string },
    toolRequest: ToolCallItem,
    instanceId?: string
  ) => Promise<ToolResponseItem[]>;
  sendToolsCall: (instanceId: string, toolsRequest: ToolRequest[]) => Promise<unknown>;
  setIsCanvasChat: (flag: boolean, conversationId: string) => unknown;
  canvasInstances: CanvasInstance[];
  closeCanvasInstance: (instanceId: string) => void;
  setCanvasInstance: (
    instanceId: string,
    extAppName: string,
    operations?: {
      showUiData?: { fileUrl?: string; onLoadCallback: () => unknown };
      restoreUiState?: string;
    }
  ) => unknown;
  setActiveTab: (instanceId: string) => void;
  sendNewFileUrlToIframe: (instanceId: string, url: string) => void;
  toolCalls: (toolRequests: ToolCallMessagePart['toolCalls']) => Promise<ToolResponseItem[]>;
  sendToolResponseMessageToChat: (toolResponses: ToolResponseItem[]) => Promise<unknown>;
}>({
  registerIframe: () => {},
  unregisterIframe: () => {},
  setFileInIframe: () => Promise.reject('boilerplate setFileInIframe'),
  setIsCanvasChat: () => {},
  sendToolsCall: () => Promise.reject('boilerplate sendToolsCall'),
  canvasInstances: [],
  closeCanvasInstance: () => {},
  setCanvasInstance: () => Promise.reject('boilerplate setChatInstance'),
  setActiveTab: (instanceId: string) => {},
  sendNewFileUrlToIframe: () => Promise.reject('boilerplate sendNewFileUrlToIframe'),
  toolCalls: () => Promise.reject('boilerplate toolCalls'),
  sendToolResponseMessageToChat: () => Promise.reject('boilerplate sendToolResponseMessageToChat'),
});

export const useCanvasHolder = () => React.useContext(TCanvasHolderContext);

export const CanvasHolderProvider = TCanvasHolderContext.Provider;

const EMPTY_INSTANCES: Record<string, { blackbox?: string; externalAppId: string }> = {};
export const CanvasHolderContext: FC<{
  getCanvasFileUpdateFunctionToTop: MutableRefObject<(instanceId: string, url: string) => unknown>;
  conversationId: string;
  externalInstances?: Record<string, { blackbox?: string; externalAppId: string }>;
}> = memo(({ getCanvasFileUpdateFunctionToTop, conversationId, externalInstances = EMPTY_INSTANCES, children }) => {
  const CanvasTransport = useRef<Record<string, ToolEventTransport>>({});
  const { sendMessageToChat, deleteExternalInstance } = useApiService();

  const [canvasInstances, setCanvasInstances] = useState<CanvasInstance[]>([]);

  const [registerIframe, unregisterIframe] = useIframeRegistration(conversationId, CanvasTransport, setCanvasInstances);

  const setIsCanvasChat = useCallback((flag: boolean, conversationId: string) => {
    setConversationsValue(storeConversations => {
      const conversationIndex = findConversationIndex(conversationId);
      if (conversationIndex > -1) {
        storeConversations[conversationIndex].isCanvasChat = flag;
      }
    });
  }, []);

  const closeCanvasInstance = useCallback(
    (instanceId: string) => {
      setCanvasInstances(prevCanvasInstances => {
        const indexOfClosedTab = prevCanvasInstances.findIndex(chatInstance => chatInstance.instanceId === instanceId);
        const closedInstance = prevCanvasInstances[indexOfClosedTab];
        const newChatInstances = prevCanvasInstances.filter(chatInstance => chatInstance.instanceId !== instanceId);
        const currentConversation = allConversationsSg.value.find(conversation => conversation.id === conversationId);

        if (newChatInstances.length === 0 && currentConversation && currentConversation.isCanvasChat) {
          setIsCanvasChat(false, conversationId);
        } else {
          if (closedInstance?.isActive) {
            if (newChatInstances[indexOfClosedTab]) {
              // next tab becomes active if it exists
              newChatInstances[indexOfClosedTab].isActive = true;
            } else {
              // otherwise last tab becomes active
              newChatInstances[indexOfClosedTab - 1].isActive = true;
            }
          }
        }
        return newChatInstances;
      });

      const currentConversationIndex = findConversationIndex(conversationId);
      if (currentConversationIndex > -1)
        setConversationsValue(prevConvs => {
          for (let [key] of Object.entries(prevConvs[currentConversationIndex].externalInstances)) {
            if (key === instanceId) {
              delete prevConvs[currentConversationIndex].externalInstances[key];
            }
          }
        });

      deleteExternalInstance(conversationId, instanceId);
    },
    [conversationId, deleteExternalInstance, setIsCanvasChat]
  );

  const setActiveTab = useCallback((instanceId: string) => {
    setCanvasInstances(prevCanvasInstances => {
      return prevCanvasInstances.map(chatInstance => {
        chatInstance.isActive = chatInstance.instanceId === instanceId;
        return chatInstance;
      });
    });
  }, []);

  const sendNewFileUrlToIframe = useCallback(async (instanceId: string, url: string) => {
    if (CanvasTransport.current[instanceId]) {
      CanvasTransport.current[instanceId].sendNewFileUrlToIframe(url);
    }
  }, []);
  getCanvasFileUpdateFunctionToTop.current = sendNewFileUrlToIframe;

  const sendToolResponseMessageToChat = useCallback(
    async (toolResponses: ToolResponseItem[]) => {
      const result = await sendMessageToChat(conversationId, [
        {
          type: 'toolResponse',
          toolResponse: toolResponses,
        },
      ]);
      streamMessage(conversationId);
      if ('meta' in result.data && result.data.meta['isProcessing']) {
        setConversationsValue(prevConvs => {
          let indexToUpdate = findConversationIndex(conversationId);
          if (indexToUpdate > -1) {
            prevConvs[indexToUpdate].status = 'BUILDING';
            prevConvs[indexToUpdate].messageIsStreaming = true;
          }
        });
      }
    },
    [conversationId, sendMessageToChat]
  );

  const setCanvasInstance = useCallback(
    (
      instanceId: string,
      extAppName: string,
      operations?: {
        showUiData?: {
          onLoadCallback: () => unknown;
          fileUrl?: string;
        };
        restoreUiState?: string;
      }
    ) => {
      setCanvasInstances(prevCanvasInstances => {
        const newCanvasInstance = externalApps.value.find(app => app.name === extAppName);
        if (newCanvasInstance === undefined) return prevCanvasInstances;

        const showUiData = operations?.showUiData;
        const restoreUiState = operations?.restoreUiState;
        const newChatInstances = [...prevCanvasInstances];
        newChatInstances.push({
          instanceId,
          url: newCanvasInstance.resources.index,
          title: newCanvasInstance.localization?.[localize.getLocale()],
          onLoad: async () => {
            if (showUiData) {
              showUiData.onLoadCallback();
              if (showUiData.fileUrl) sendNewFileUrlToIframe(instanceId, showUiData.fileUrl);
            }
            if (restoreUiState) {
              CanvasTransport.current[instanceId].transport.postMessage('restore_state', restoreUiState);
            }
          },
        });
        return newChatInstances;
      });
      setActiveTab(instanceId);
    },
    [sendNewFileUrlToIframe, setActiveTab]
  );

  const sendToolsCall = useCallback(async (instanceId: string, eventData: ToolRequest[]) => {
    if (CanvasTransport.current[instanceId]) {
      return await CanvasTransport.current[instanceId].toolsCall<string>(eventData);
    }

    return Promise.reject('No instance with id ' + instanceId);
  }, []);

  const setFileInIframe = useCallback(
    async (
      { uri, type, name }: { uri: string; type: string; name: string },
      toolRequest: ToolCallItem,
      instanceId?: string
    ) => {
      const { data } = await apiClient.get(new URL(uri).pathname, {
        responseType: 'arraybuffer',
        withCredentials: true,
      });
      const responses: { toolCallId: string; name: string; response: string }[] = [];
      if (!instanceId) {
        for (const toolEventTransport of Object.values(CanvasTransport.current)) {
          if (toolEventTransport) {
            const response = await toolEventTransport.uploadFileToIframe(
              { data, type, name },
              {
                id: toolRequest.id || '',
                type: 'function',
                function: {
                  name: toolRequest.function.name,
                  arguments: JSON.parse(toolRequest.function.arguments),
                },
              }
            );
            if (response) {
              responses.push(response);
            }
          }
        }
      }
      if (instanceId && CanvasTransport.current[instanceId]) {
        const response = await CanvasTransport.current[instanceId].uploadFileToIframe(
          { data, type, name },
          {
            id: toolRequest.id || '',
            type: 'function',
            function: {
              name: toolRequest.function.name,
              arguments: JSON.parse(toolRequest.function.arguments),
            },
          }
        );
        if (response) {
          responses.push(response);
        }
      }
      return responses;
    },
    []
  );

  const toolCalls = useCallback(async (toolCallRequest: ToolCallMessagePart['toolCalls']) => {
    const allResults: any[] = [];
    const toolCallRequestToToolRequestByInstanceId = toolCallRequest.reduce<Record<string, ToolRequest[]>>(
      (acc, currentItem) => {
        try {
          const fnArgs = JSON.parse(currentItem.function.arguments);
          if (fnArgs.externalInstanceId) {
            acc[fnArgs.externalInstanceId] ??= [];
            acc[fnArgs.externalInstanceId].push({
              id: currentItem.id!,
              type: 'function',
              function: {
                name: currentItem.function.name,
                arguments: omit(fnArgs, 'externalInstanceId'),
              },
            });
          } else {
            acc['default'] ??= [];
            acc['default'].push({
              id: currentItem.id!,
              type: 'function',
              function: {
                name: currentItem.function.name,
                arguments: fnArgs,
              },
            });
          }
        } catch (e) {
          // TODO not implemented. Not sure how to handle this. Because if function parameters wrong we need to send an error to LLM
        }
        return acc;
      },
      {} as Record<string, ToolRequest[]>
    );

    for (const [key, toolEventTransport] of Object.entries(CanvasTransport.current)) {
      if (toolCallRequestToToolRequestByInstanceId[key]) {
        const result = await toolEventTransport.toolsCall(toolCallRequestToToolRequestByInstanceId[key]);
        allResults.push(result);
      }
      if (toolCallRequestToToolRequestByInstanceId['default']?.length) {
        const result = await toolEventTransport.toolsCall(toolCallRequestToToolRequestByInstanceId['default']);
        allResults.push(result);
      }
    }

    const saveResults = allResults.flat();
    if (saveResults.length === 0) {
      const errorResults = Object.values(toolCallRequestToToolRequestByInstanceId).map(fns => {
        return fns.map(fn => {
          return {
            toolCallId: fn.id,
            name: fn.function.name,
            response:
              'You call function that is not available to the user.' +
              'You should first focus on special externalApp to communicate with it and only after ui is being shown to the user you can call tools for special ui.',
          };
        });
      });
      return errorResults.flat();
    }
    return saveResults.map(res => ({
      toolCallId: res.function_call.id,
      name: res.function_call.function.name,
      response: res.output,
    }));
  }, []);

  useEffect(() => {
    if (!externalInstances || Object.keys(externalInstances).length === 0) {
      setCanvasInstances([]);
      setIsCanvasChat(false, conversationId);
      return;
    }
    for (const [instanceId, externalApp] of Object.entries(externalInstances)) {
      if (CanvasTransport.current[instanceId]) continue;
      setCanvasInstance(
        instanceId,
        externalApp.externalAppId,
        externalApp.blackbox ? { restoreUiState: externalApp.blackbox } : undefined
      );
    }
  }, [conversationId, externalInstances, setCanvasInstance, setIsCanvasChat]);

  useEffect(() => {
    return () => {
      dropSilentMessages();
      dropFilesToUpload();
    };
  }, [conversationId]);

  return (
    <CanvasHolderProvider
      value={{
        registerIframe,
        unregisterIframe,
        setFileInIframe,
        sendToolsCall,
        setIsCanvasChat,
        canvasInstances,
        closeCanvasInstance,
        setCanvasInstance,
        setActiveTab,
        toolCalls,
        sendNewFileUrlToIframe,
        sendToolResponseMessageToChat,
      }}
    >
      {children}
    </CanvasHolderProvider>
  );
});
CanvasHolderContext.displayName = 'memo(CanvasHolderContext)';
