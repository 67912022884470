import { TemplateResponse } from '@just-ai/api/dist/generated/AppsAdapter';

import { Message } from '../../../../types/chat';
import { ContextInstruction } from '../interface';

const textInstructions: ContextInstruction[] = [
  {
    caption: 'actionButtons:openInEditor',
    instructions: [{ command: 'show_ui', params: JSON.stringify({ externalAppId: 'md_editor' }) }],
  },
  {
    caption: 'actionButtons:summarize',
    instructions: [{ promptKey: 'summary' }],
  },
  {
    caption: 'actionButtons:rewrite',
    instructions: [{ externalAppId: 'textGenerator', command: 'copywriter', promptKey: 'rewrite' }],
  },
  {
    caption: 'actionButtons:restyle',
    instructions: [{ externalAppId: 'textGenerator', command: 'copywriter', promptKey: 'restyle' }],
  },
  {
    caption: 'actionButtons:smm',
    instructions: [{ externalAppId: 'textGenerator', command: 'write_advertisement_post' }],
  },
  {
    caption: 'actionButtons:letter',
    instructions: [{ externalAppId: 'textGenerator', command: 'write_business_email' }],
  },
  {
    caption: 'actionButtons:imagine',
    instructions: [{ externalAppId: 'imageProcessor', command: 'generate_image' }],
  },
];

function resolveImageInstructions(url: string): ContextInstruction[] {
  return [
    {
      caption: 'actionButtons:openInEditor',
      instructions: [{ command: 'show_ui', params: JSON.stringify({ externalAppId: 'image_editor', fileUrl: url }) }],
    },
    {
      caption: 'actionButtons:removeBackground',
      instructions: [
        { externalAppId: 'imageProcessor', command: 'remove_background', params: JSON.stringify({ imageUrl: url }) },
      ],
    },
    {
      caption: 'actionButtons:upscale',
      instructions: [
        { externalAppId: 'imageProcessor', command: 'upscale_image', params: JSON.stringify({ imageUrl: url }) },
      ],
    },
  ];
}

const imageTextRegexp = /^!\[.*]\((http.*\/api\/appsAdapter\/files\/.*\/download)\)$/;
function resolveImageUrl(messageContent: Message['content']) {
  if (messageContent.length !== 1) {
    return null;
  }
  const [singleMessagePart] = messageContent;
  if (singleMessagePart.type === 'image') {
    return singleMessagePart.url;
  }
  if (singleMessagePart.type !== 'text') {
    return null;
  }
  const match = singleMessagePart.text.match(imageTextRegexp);
  return match?.[1] ?? null;
}

export default function resolveAvailableInstructions(template: TemplateResponse, messageContent: Message['content']) {
  if (template.clientFeatures?.imageContextInstructions) {
    const imageUrl = resolveImageUrl(messageContent);
    if (imageUrl) {
      return resolveImageInstructions(imageUrl);
    }
  }
  if (template.clientFeatures?.textContextInstructions && messageContent.every(message => message.type === 'text')) {
    return textInstructions;
  }
  return [];
}
