export const actionsDropDownLocalization = {
  'actionButtons:openInEditor': { eng: 'Open in editor', ru: 'Открой в редакторе' },
  'actionButtons:summarize': { eng: 'Write a summary', ru: 'Напиши краткое содержание' },
  'actionButtons:rewrite': { eng: 'Rewrite', ru: 'Перепиши' },
  'actionButtons:restyle': { eng: 'Change style', ru: 'Измени стиль' },
  'actionButtons:smm': { eng: 'Write an SMM post', ru: 'Напиши SMM-пост' },
  'actionButtons:letter': { eng: 'Write a letter from text', ru: 'Напиши письмо по тексту' },
  'actionButtons:imagine': { eng: 'Create an image for the text', ru: 'Создай картинку к тексту' },
  'actionButtons:removeBackground': { eng: 'Remove background', ru: 'Удали фон' },
  'actionButtons:upscale': { eng: 'Upscale resolution x2', ru: 'Увеличь разрешение x2' },
  'actionButtons:more': { eng: 'More actions', ru: 'Больше действий' },
  'actionButtonsTrigger:do': { eng: 'Do…', ru: 'Сделай…' },
};
