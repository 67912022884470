import React, { useCallback } from 'react';

import { Spinner } from '@just-ai/just-ui';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import ConversationDelete from './ConversationDelete';
import DivInsteadOfAnchor from './DivInsteadOfAnchor';
import styles from './style.module.scss';
import { conversationLink } from '../../../routes';
import { Conversation } from '../../../types/chat';
import { isMobile } from '../../../utils/app/common';

interface Props {
  conversation: Conversation;
  onClick: (conversation: Conversation) => void;
  dataTestId?: string;
}

export default function ConversationItem({ conversation, dataTestId, onClick: handleClick }: Props) {
  const renderIcon = useCallback(() => {
    if (conversation.config.info?.svgImageIcon)
      return (
        <div
          className='d-flex width-20 justify-content-center'
          dangerouslySetInnerHTML={{ __html: conversation.config.info.svgImageIcon }}
        />
      );
    return conversation.config.info?.icon || `💬`;
  }, [conversation.config.info?.icon, conversation.config.info?.svgImageIcon]);

  return (
    <NavLink
      component={DivInsteadOfAnchor}
      to={conversationLink(conversation.id)}
      activeClassName={styles.selected}
      data-test-id={dataTestId}
      className={cn(styles.navLink, 'relative text-decoration-none w-100 overflow-hidden cursor-pointer')}
    >
      <div
        className={cn(
          'flex cursor-pointer flex-grow-1 justify-start rounded-lg p-3 text-sm transition-colors overflow-hidden'
        )}
        onClick={() => handleClick(conversation)}
        role='button'
      >
        <div className={cn(`flex align-items-center text-left  w-100`, styles.chatName)}>
          <div className='margin-right-6 font-size-16 relative'>
            {conversation.status === 'BUILDING' || conversation.messageIsStreaming ? (
              <Spinner size={isMobile() ? 'lg' : 'md'} inline />
            ) : (
              renderIcon()
            )}
            {conversation.hasUnreadChanges ? <div className={cn(styles.tip, styles.tip_absolute)} /> : null}
          </div>
          <div className='font-size-14 text-ellipsis overflow-hidden'>
            {conversation.hasUnreadChanges ? <b>{conversation.name}</b> : conversation.name}
          </div>
        </div>
      </div>

      <ConversationDelete
        conversationMessageIsStreaming={conversation.messageIsStreaming}
        conversationId={conversation.id}
      />
    </NavLink>
  );
}
