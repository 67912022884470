import React, { FC, memo, useCallback } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { Item } from 'react-photoswipe-gallery';

import { GalleryImage, ImageGalleryProps } from './commonTypes';
import { ImageGalleryButton } from './ImageGalleryButton';
import ImageShowUiButton from './ImageShowUiButton';
import { isConversationStreaming, isRequestsLimitExceeded } from '../../../models/conversations';
import { setConversationStatus, setMessageIsStreaming } from '../../../models/conversations/signals';
import { appsAdapterService } from '../../../services/service';
import classes from '../style.module.scss';

export const ImageGalleryItem: FC<
  { image: GalleryImage; index: number } & Omit<ImageGalleryProps, 'images' | 'withExistingGallery'>
> = memo(({ image, errorHandler, selectedConversationId, isAssistantChat, galleryId, index }) => {
  const { t } = useTranslation();

  const downloadImage = useCallback(() => {
    window.open(image.largeURL, '_blank');
  }, [image.largeURL]);

  const upscaleHandle = useCallback(async () => {
    if (!selectedConversationId) return;
    await appsAdapterService.upscaleImage(selectedConversationId, image.largeURL);
    setMessageIsStreaming(selectedConversationId, true);
    setConversationStatus(selectedConversationId);
  }, [image.largeURL, selectedConversationId]);

  const regenerateHandle = useCallback(async () => {
    if (!selectedConversationId) return;
    await appsAdapterService.regenerateImage(selectedConversationId, image.largeURL);
    setMessageIsStreaming(selectedConversationId, true);
    setConversationStatus(selectedConversationId);
  }, [image.largeURL, selectedConversationId]);

  const buttonsDisabled = isConversationStreaming(selectedConversationId) || isRequestsLimitExceeded.value;

  return (
    <div className={cn(classes.chat_imageItem)}>
      <Item
        original={image.largeURL}
        thumbnail={image.thumbnailURL}
        width={0.75 * window.innerWidth}
        height={0.75 * window.innerWidth}
        alt=''
      >
        {({ ref, open }) => (
          <img
            ref={ref}
            className='cursor-pointer'
            onClick={open}
            alt=''
            src={image.thumbnailURL}
            onError={errorHandler}
          />
        )}
      </Item>
      {!!selectedConversationId && (
        <div className={cn(classes.chat_imageItem__overlay)}>
          {!!isAssistantChat && (
            <>
              <ImageGalleryButton
                icon='faX2'
                disabled={buttonsDisabled}
                id={`${galleryId}-${index}-buttonX2Id`}
                tooltipText={t('Chat:ImageGallery:X2')}
                dataTestId={`Chat.ImageGallery.X2.${index}`}
                onClick={upscaleHandle}
              />
              <ImageGalleryButton
                icon='farSyncAlt'
                disabled={buttonsDisabled}
                onClick={regenerateHandle}
                id={`${galleryId}-${index}-buttonRegenerateId`}
                tooltipText={t('Chat:ImageGallery:Regenerate')}
                dataTestId={`Chat.ImageGallery.Regenerate.${index}`}
              />
              <ImageShowUiButton
                tooltipWrapperId={`${galleryId}-${index}-buttonOpenEditorId`}
                imageUrl={image.largeURL}
                selectedConversationId={selectedConversationId}
              />
            </>
          )}
          <ImageGalleryButton
            icon='farArrowToBottom'
            disabled={false}
            id={`${galleryId}-${index}-buttonDownloadId`}
            tooltipText={t('Chat:ImageGallery:Download')}
            dataTestId={`Chat.ImageGallery.Download.${index}`}
            onClick={downloadImage}
          />
        </div>
      )}
    </div>
  );
});
