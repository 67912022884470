var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Tooltip as RsTooltip, UncontrolledTooltip as RsUncontrolledTooltip, } from 'reactstrap';
import classNames from 'classnames';
import './style.scss';
import { useToggle } from '../utils';
export const Tooltip = (_a) => {
    var { autohide = true, textAlign = 'left' } = _a, props = __rest(_a, ["autohide", "textAlign"]);
    const [hasTarget, setHasTarget, setHasNotTarget] = useToggle(false);
    useEffect(() => {
        if (!props.target || (typeof props.target === 'string' && !document.getElementById(props.target))) {
            return setHasNotTarget();
        }
        setHasTarget();
    }, [props.target, setHasNotTarget, setHasTarget]);
    if (!hasTarget)
        return null;
    const className = classNames(props.className, { 'justui_tooltip-autohide': autohide });
    const innerClassName = classNames(props.innerClassName, {
        'text-left': textAlign === 'left',
        'text-right': textAlign === 'right',
    });
    return props.toggle ? (_jsx(RsTooltip, Object.assign({}, props, { className: className, innerClassName: innerClassName, autohide: autohide, children: props.children }))) : (_jsx(RsUncontrolledTooltip, Object.assign({}, props, { className: className, innerClassName: innerClassName, autohide: autohide, children: props.children })));
};
