import { useEffect, useMemo } from 'react';

import { TemplateResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { useSignalEffect } from '@preact/signals-react';

import useConversationsListRequest from './conversationsList/useConverationsList';
import useMainConversationRequest from './mainConversation/useMainConversation';
import {
  allConversationsSg,
  conversationsInvalidatedSg,
  conversationsLoadedSg,
  mainConversationSg,
  setConversationsValue,
} from './signals';
import { rawConversationToConversation } from './utils';
import { appHasSystemFeature } from '../../api/cc.api';
import { isAlpha } from '../../isAlpha';
import { Conversation } from '../../types/chat';
import { notEmpty } from '../../utils/common';
import { isDemoMode } from '../../api/client';

function repairHistory(conversation: Conversation, prevConversations: Conversation[]): Conversation {
  return {
    ...conversation,
    history: prevConversations.find(({ id }) => id === conversation.id)?.history ?? conversation.history,
    isCanvasChat: conversation.externalInstances ? Object.keys(conversation.externalInstances).length > 0 : false,
  };
}

export const useConversations = (
  {
    templatesMap,
    abortController,
  }: {
    templatesMap: Record<string, TemplateResponse> | null;
    abortController?: AbortController;
  } = {
    templatesMap: null,
  }
) => {
  const hasTemplates = templatesMap != null;
  const isMainConversationAvailable = isDemoMode.value || Boolean(!isAlpha && appHasSystemFeature('assistant'));

  const {
    result: mainConversationResult,
    error: mainConversationError,
    loaded: mainConversationLoaded,
    invalidate: invalidateMainConversation,
  } = useMainConversationRequest({
    enabled: hasTemplates && isMainConversationAvailable,
    abortController,
  });

  const mainConversationCompleted = mainConversationLoaded || !isMainConversationAvailable;

  const mainConversation = mainConversationResult ?? null;

  const {
    result: conversationsListResult,
    error: conversationsListError,
    loaded: conversationsListLoaded,
    invalidate: invalidateConversationsList,
  } = useConversationsListRequest({
    abortController,
    enabled: hasTemplates && mainConversationCompleted,
    query: mainConversation ? { match: { _id: { $ne: mainConversation.id } } } : null,
  });

  const actualConversations = useMemo(() => {
    if (!templatesMap) {
      return [];
    }
    const conversationsList = conversationsListResult?.data?.conversations ?? [];
    return conversationsList
      .map(conversationResponse => {
        const chatTemplate = templatesMap[conversationResponse.app?.template];
        if (!chatTemplate) {
          return null;
        }
        return rawConversationToConversation(conversationResponse, chatTemplate);
      })
      .filter(notEmpty);
  }, [templatesMap, conversationsListResult?.data?.conversations]);

  const adaptedMainConversation = useMemo(() => {
    if (templatesMap && mainConversation) {
      const mainTemplate = templatesMap[mainConversation.app?.template];
      if (mainTemplate) {
        return rawConversationToConversation(mainConversation, mainTemplate);
      }
    }
    return null;
  }, [templatesMap, mainConversation]);

  useEffect(() => {
    const loaded = conversationsListLoaded && mainConversationCompleted;
    if (!loaded) {
      return;
    }

    const prevConversations = allConversationsSg.peek();

    const nextConversations = adaptedMainConversation
      ? [adaptedMainConversation, ...actualConversations]
      : actualConversations;

    // An empty history arrives here, and this method is not for updating the history.
    // Therefore, as a workaround, we use the history from the previous list of conversations.
    // This helps avoid a bug where the open conversation gets updated in this method (after invalidation)
    // and loses its history. If a new conversation is opened, selectedConversationSg will change, and
    // an effect requesting history for the new conversation will be called.
    // There are future plans to migrate to react-query for proper back-end responses caching
    const repairedNextConversations = nextConversations.map(conversation =>
      repairHistory(conversation, prevConversations)
    );

    if (adaptedMainConversation) {
      mainConversationSg.value = repairedNextConversations[0];
    }

    setConversationsValue(repairedNextConversations);
    conversationsLoadedSg.value = loaded;
  }, [conversationsListLoaded, mainConversationCompleted, actualConversations, adaptedMainConversation]);

  useSignalEffect(() => {
    if (conversationsInvalidatedSg.value) {
      conversationsInvalidatedSg.value = false;
      invalidateMainConversation();
      invalidateConversationsList();
    }
  });

  return {
    actualConversations,
    conversationsListError,
    mainConversationError,
    loaded: conversationsListLoaded && mainConversationCompleted,
  };
};
