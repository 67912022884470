import React, { useCallback, useContext } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import { conversationsSg, mainConversationSg } from 'models/conversations/signals';

import { ChatbarSettings } from './components/ChatbarSettings';
import { Conversations } from './components/Conversations';
import AppContext from '../../contexts/appContext';
import { getUserChatHistory } from '../../models/conversations/apiHooks';
import { goToConversation, goToMainConversation } from '../../routes';
import { Conversation } from '../../types/chat';
import { isMobile } from '../../utils/app/common';
import { hideAgentSettingsMobileToggle } from '../Settings/signals';
import Sidebar from '../Sidebar';

export const Chatbar = () => {
  const { t } = useTranslation();

  const {
    state: { showChatbar },
    dispatch: homeDispatch,
  } = useContext(AppContext);

  const mainConversation = mainConversationSg.value;
  const conversations = conversationsSg.value;

  const handleToggleChatbar = useCallback(() => {
    homeDispatch({ field: 'showChatbar', value: !showChatbar });
    localStorage.setItem('showChatbar', JSON.stringify(!showChatbar));
  }, [homeDispatch, showChatbar]);

  const handleConversationClick = useCallback(
    async (conversation: Conversation) => {
      if (mainConversation != null && conversation.id === mainConversation.id) {
        goToMainConversation();
      } else {
        goToConversation(conversation.id);
      }
      if (conversation.hasUnreadChanges) {
        await getUserChatHistory(conversation.id, conversation.app.template);
      }
      if (isMobile()) {
        hideAgentSettingsMobileToggle();
        handleToggleChatbar?.();
      }
    },
    [handleToggleChatbar, mainConversation]
  );

  return (
    <Sidebar
      side='left'
      isOpen={showChatbar}
      addItemButtonTitle={t('allTemplates')}
      onMainConversationClick={handleConversationClick}
      itemComponent={<Conversations conversations={conversations} onConversationClick={handleConversationClick} />}
      toggleOpen={handleToggleChatbar}
      footerComponent={<ChatbarSettings toggleSidebar={handleToggleChatbar} />}
    />
  );
};
