import { signal } from '@preact/signals-react';

export const isOpenAgentSettings = signal(false);

export const handleAgentSettingsMobileToggle = () => {
  isOpenAgentSettings.value = !isOpenAgentSettings.value;
};

export const hideAgentSettingsMobileToggle = () => {
  isOpenAgentSettings.value = false;
};
