import React, { useRef } from 'react';

import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownToggle,
  Icon,
  useToggle,
  useTranslation,
} from '@just-ai/just-ui';
import cn from 'classnames';
import EmojiPickerLib, { EmojiClickData } from 'emoji-picker-react';
import { useController } from 'react-hook-form';

import styles from './style.module.scss';
import { FormBuilderField } from '../../types';

type EmojiPickerProps = Omit<FormBuilderField, 'data' | 'handleFileUpload'>;

export default function EmojiPicker(props: EmojiPickerProps) {
  const { name, control, required } = props;

  const dropdownRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { field, fieldState } = useController({ name, control, rules: { required } });

  const [showPicker, openPicker, closePicker] = useToggle(false);

  const handleChange = (args: EmojiClickData) => {
    field.onChange(args.emoji);
    closePicker();
  };

  const handleTogglePicker = (event: any) => {
    if (dropdownRef.current?.contains(event.target) && !showPicker) {
      return openPicker();
    } else {
      closePicker();
    }
  };

  return (
    <div className='w-full flex justify-center pt-2'>
      <div className='flex flex-column justify-center' ref={dropdownRef}>
        <Dropdown className='flex justify-center' direction='down' isOpen={true} toggle={handleTogglePicker}>
          <DropdownToggle
            iconButton
            color='none'
            className={cn(styles.emojiPickerBtn, { [styles['emojiPickerBtn--empty']]: !field.value })}
            data-test-id='Emoji.Picker'
          >
            {field.value ? (
              <p className={styles.pickedEmoji}>{field.value}</p>
            ) : (
              <Icon name='farPlus' color='secondary' size='lg' />
            )}
          </DropdownToggle>
          <DropdownMenu
            data-test-id='Emoji.Picker.Menu'
            right
            className={cn(styles.emojiPickerMenu, { visible: showPicker })}
          >
            <EmojiPickerLib lazyLoadEmojis onEmojiClick={handleChange} />
          </DropdownMenu>
        </Dropdown>
        {fieldState.error && <span className='text-small text-danger'>{t('Emoji:required:err')}</span>}
      </div>
    </div>
  );
}
