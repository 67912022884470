import React, { useEffect, useMemo, useState } from 'react';

import { Banner, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { cloneDeep } from 'lodash';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

import FormBuilder from './FormBuilder';
import TemplateFormButtons from './TemplateFormButtons';
import { FieldConfig } from './useFormBuilder';
import { templates } from '../../../../models/templates';
import { AgentFileUploader, AgentType, CreatedAgent, ValidationTemplate } from '../../../../types/agents';
import { isConversationTemplateValid } from '../../../../utils/app/conversation';
import { handleAgentSettingsMobileToggle } from '../../../Settings/signals';
import jsonData from '../../JsonData';
import styles from '../../style.module.scss';
import ValidationError from '../ValidationError/ValidationError';

type TemplateFormProps = {
  userId?: string;
  template: AgentType | CreatedAgent;
  submit: () => Promise<void>;
  isEdit?: boolean;
  handleFileUpload: AgentFileUploader;
  data?: unknown;
  locale: string;
  cancel: () => unknown;
  isOpenFromTelegram: boolean;
  formMethods: UseFormReturn<FieldValues>;
  showWarningRequestsBanner?: boolean;
  /**
   * Set this to false to hide the footer even when `isEdit` is true.
   */
  showFooter?: boolean;
  disabledUpdateButton?: boolean;
  disabledInputs?: boolean;
  isDirtyCallback?: (value: boolean) => void;
  errorState: ValidationTemplate;
};
const TemplateForm = ({
  template,
  isEdit = false,
  handleFileUpload,
  data = jsonData,
  isOpenFromTelegram,
  formMethods,
  submit,
  showWarningRequestsBanner = false,
  showFooter = true,
  disabledUpdateButton,
  disabledInputs,
  isDirtyCallback,
  errorState,
}: TemplateFormProps) => {
  const { t } = useTranslation();
  const { templatesMap } = templates.value;

  const [creating, setCreating] = useState(false);

  const createdFromTemplateId = (template as CreatedAgent)?.id;

  const fieldsList = useMemo(() => {
    if (Object.keys(templatesMap[template.template]?.params || {}).length === 0) return null;
    return Object.entries(templatesMap[template.template]?.params || {}).map(([paramName, templateParam]) => {
      const param = cloneDeep(templateParam);
      //@ts-ignore
      param.name = paramName;

      switch (true) {
        case createdFromTemplateId && param.type === 'group': {
          Object.keys(param.params).forEach(
            groupParamName => (param.params[groupParamName].value = template.params?.[groupParamName])
          );
          break;
        }
        case createdFromTemplateId && template?.params?.[paramName] !== undefined: {
          param.value = template?.params?.[paramName];
          break;
        }
        case template?.params?.[paramName]?.['value'] !== undefined: {
          param.value = template?.params?.[paramName]?.['value'];
          break;
        }
        case param.type === 'group': {
          Object.keys(param.params).forEach(
            groupParamName => (param.params[groupParamName].value = template.params?.[groupParamName])
          );
          break;
        }
        case templatesMap[template.template]?.params[paramName]['value'] !== undefined: {
          param.value = templatesMap[template.template]?.params[paramName]['value'];
          break;
        }
        default:
          param.value = '';
      }

      return param as FieldConfig;
    });
  }, [templatesMap, createdFromTemplateId, template.params, template.template]);

  useEffect(() => {
    if (!isOpenFromTelegram && !isEdit && Boolean(templatesMap?.[template.template]?.autoCreate) && !creating) {
      setCreating(true);
      submit().catch(() => {
        templatesMap[template.template].autoCreate = false;
        setCreating(false);
      });
    }
  }, [templatesMap, creating, isEdit, submit, template.template, isOpenFromTelegram]);

  useEffect(() => {
    isDirtyCallback && isDirtyCallback(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty, isDirtyCallback]);

  const isError = !isConversationTemplateValid(template);
  const errorFields = errorState?.map(el => el?.id) ?? [];

  if (creating || isError) {
    return <style>{'.modal{display:none!important};'}</style>;
  }

  return (
    <div
      className={cn(styles.templateForm__wrapper, {
        [styles.templateForm__wrapper__edit]: isEdit,
        [styles.templateForm__wrapper__tg]: isOpenFromTelegram,
      })}
      data-test-id={`TemplateForm.wrapper:${template.template}`}
    >
      <div className={cn('flex flex-col', styles.templateForm__header)}>
        {isEdit && templatesMap[template.template]?.info?.title && (
          <b className='color-gray-950 font-size-16 line-height-24 margin-bottom-4'>
            {templatesMap[template.template]?.info?.title}
          </b>
        )}
        <p className='line-height-21'>{template.info?.description}</p>
      </div>
      <FormProvider {...formMethods}>
        <div className={cn('flex flex-col gap-24 flex-grow-1', styles.templateForm__body)}>
          {fieldsList ? (
            fieldsList.map(param => {
              return (
                <FormBuilder
                  key={param.name}
                  control={formMethods.control}
                  isEdit={isEdit}
                  data={data}
                  param={param}
                  handleFileUpload={handleFileUpload}
                  disabled={disabledInputs}
                  error={errorFields.includes(param.name)}
                />
              );
            })
          ) : (
            <div>{t('noParams')}</div>
          )}
          {showWarningRequestsBanner && !isOpenFromTelegram ? (
            <Banner
              withIcon
              BannerText={() => <p>{t('noMoreThan3RequestsToCreateApp')}</p>}
              type='warning'
              iconSize='lg'
            />
          ) : null}

          {!!errorState && <ValidationError errorState={errorState} />}
        </div>
      </FormProvider>
      {isEdit && showFooter && (
        <div className={cn(styles.templateForm__footer)}>
          <TemplateFormButtons
            template={template as CreatedAgent}
            onCancelClick={handleAgentSettingsMobileToggle}
            onSubmitClick={submit}
            isSubmitDisabled={disabledUpdateButton}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(TemplateForm);
