import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';

import { MAX_FILE_SIZE_CHAT } from './consts';
import { chatFileFromFileService } from './signals/ChatUpdateSignal';
import { useTranslation } from '../../contexts/translationContext';
import { addAlert } from '../../models/alerts';

export const useClipboardPasteHook = (
  inputFileRef: RefObject<HTMLInputElement>,
  MAX_FILES: number,
  isFilesAvailable: boolean,
  setFiles: Dispatch<SetStateAction<File[]>>
) => {
  const { t } = useTranslation();

  useEffect(() => {
    const listener = (e: ClipboardEvent) => {
      if (e.clipboardData && e.clipboardData.files && e.clipboardData.files.length > 0) {
        e.preventDefault();
        const files = e.clipboardData.files;
        const filesArr = Array.from(files).filter(file => file.type.startsWith('image/'));
        if (filesArr.some(file => file.size / 1024 / 1024 > MAX_FILE_SIZE_CHAT)) {
          if (inputFileRef.current) {
            inputFileRef.current.files = null;
            inputFileRef.current.value = '';
          }
          return addAlert(t('fileSizeError__param', { size: MAX_FILE_SIZE_CHAT }), 'info');
        }
        if (filesArr.length) {
          chatFileFromFileService.value = null;
          setFiles(prevFiles => {
            return [...filesArr, ...prevFiles].splice(0, MAX_FILES);
          });
        }
      }
    };
    if (isFilesAvailable) {
      document.addEventListener('paste', listener);
    }
    return () => {
      document.removeEventListener('paste', listener);
    };
  }, [MAX_FILES, inputFileRef, isFilesAvailable, setFiles, t]);
};
