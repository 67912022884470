import React, { memo } from 'react';

import { Button, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import AccountLogOut from './AccountLogOut';
import styles from './style.module.scss';
import { ccOptions, appHasSystemFeature } from '../../api/cc.api';
import { goToInvitationPage, isDemoMode } from '../../api/client';
import { currentUser, hasPermission } from '../../models/currentUser';

const AccountInfo = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.accountPage__block, styles.accountPage__block__org)}>
      <p className={styles.accountPage__block__header}>{t('myProfile')}</p>
      {!!currentUser.value?.email?.length && (
        <p className={styles.accountPage__block__data}>{currentUser.value?.email}</p>
      )}
      {!!currentUser.value?.fullName?.length && (
        <p className={styles.accountPage__block__header}>{currentUser.value?.fullName}</p>
      )}
      {!!currentUser.value?.accountId && (
        <p className={styles.accountPage__block__data}>{`ID: ${currentUser.value?.accountId}`}</p>
      )}

      <div className='flex-column gap-10 mt-4'>
        {ccOptions.value && hasPermission('ROLES_WRITE') && appHasSystemFeature('invite_users') && (
          <Button
            data-test-id='Account.inviteUsers'
            color='primary'
            outline
            onClick={() => goToInvitationPage(currentUser.value?.accountId)}
          >
            {t('inviteUsers')}
          </Button>
        )}

        {!isDemoMode.value && <AccountLogOut />}
      </div>
    </div>
  );
});

export default AccountInfo;
