import { useState, useCallback, useRef } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { useAppContext } from '../../../contexts/appContext';
import { findConversationIndex } from '../../../models/conversations';
import { setConversationsValue } from '../../../models/conversations/signals';
import { guideTourEvent$ } from '../../../modules/GuideTourStepper/guideTourEvents';
import useApiService from '../../../services/useApiService';
import { AgentType, ValidationTemplate } from '../../../types/agents';
import { Conversation } from '../../../types/chat';
import { processConversationContext, processHistory } from '../../../utils/app/conversation';
import {
  appHasUnsavedChanges,
  resetContextSilent,
  settingsModalIsOpenedFromChat,
} from '../../Chat/signals/ChatUpdateSignal';
import { isOpenAgentSettings } from '../signals';

export default function useUpdateAgentSettings(selectedConversation: Conversation) {
  const { t } = useTranslation();
  const { addAlert } = useAppContext();
  const { prevalidateApp, updateUserChat, uploadFile } = useApiService();

  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState<ValidationTemplate>(null);
  const [updateData, setUpdateData] = useState<AgentType | null>(null);

  const cancelRefUpdating = useRef<AbortController>();
  const settingsFormRef = useRef<UseFormReturn<FieldValues>>();

  const handleAgentUpdate = useCallback(
    async agentData => {
      setLoading(true);
      setErrorState(null);
      const sendData = {
        template: agentData.template,
        params: agentData.params,
      };
      cancelRefUpdating.current = new AbortController();

      try {
        const { data: checkResult } = await prevalidateApp(sendData, cancelRefUpdating.current);
        if (!!checkResult.deniedEntities?.length) {
          setErrorState(checkResult.deniedEntities as ValidationTemplate);
          setUpdateData(null);
          return;
        }
        const { data: newConversation } = await updateUserChat(selectedConversation.id, sendData);
        const updatedConversation = cloneDeep(selectedConversation);
        updatedConversation.history = processHistory(newConversation.history);
        updatedConversation.contextValue = processConversationContext(updatedConversation.history);
        setConversationsValue(conversations => {
          let conversationForUpdateIndex = findConversationIndex(updatedConversation.id);
          if (conversationForUpdateIndex > -1) {
            conversations[conversationForUpdateIndex] = {
              ...newConversation,
              config: { ...updatedConversation.config, params: newConversation.app.params as AgentType['params'] },
              history: updatedConversation.history,
              messageIsStreaming: newConversation.status === 'BUILDING',
              contextValue: updatedConversation.contextValue,
              isCanvasChat:
                !!updatedConversation.externalInstances &&
                Object.keys(updatedConversation.externalInstances).length > 0,
            };
          }
        });

        setUpdateData(null);
        appHasUnsavedChanges.value = false;
        settingsFormRef.current?.reset(newConversation.app.params);
        isOpenAgentSettings.value = false;
        if (!resetContextSilent.value) addAlert(t('agentUpdateSuccess'), 'success');
        guideTourEvent$.next('AgentSettings:Update:Submit');
      } catch (error) {
        addAlert(t('agentUpdateErr'));
      } finally {
        setLoading(false);
      }
    },
    [addAlert, prevalidateApp, selectedConversation, settingsFormRef, t, updateUserChat]
  );

  const handleCancelAgentUpdate = useCallback(() => {
    setUpdateData(null);
    if (!settingsModalIsOpenedFromChat.value) {
      settingsFormRef.current?.reset();
    }
    settingsModalIsOpenedFromChat.value = false;
  }, []);

  const handleUploadFile = useCallback(
    async data => {
      setLoading(true);
      try {
        const { data: fileResponse } = await uploadFile(data);
        return { fileId: fileResponse.id, fileName: fileResponse.name, url: fileResponse.url };
      } catch (error) {
        addAlert(t('fileUploadErr') + '\n' + (error as AxiosError).message);
      } finally {
        setLoading(false);
      }
    },
    [addAlert, t, uploadFile]
  );

  return {
    loading,
    errorState,
    updateData,
    settingsFormRef,
    setUpdateData,
    handleAgentUpdate,
    handleCancelAgentUpdate,
    handleUploadFile,
  };
}
