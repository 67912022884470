import React, { memo } from 'react';

import { Gallery } from 'react-photoswipe-gallery';

import 'photoswipe/dist/photoswipe.css';
import { ImageGalleryProps } from './commonTypes';
import { ImageGalleryItem } from './ImageGalleryItem';

function ImageGallery({
  galleryId,
  images,
  withExistingGallery,
  errorHandler,
  selectedConversationId,
  isAssistantChat,
}: ImageGalleryProps) {
  const galleryItems = (
    <>
      {images.map((image, index) => (
        <ImageGalleryItem
          image={image}
          key={galleryId + '-' + index}
          galleryId={galleryId}
          index={index}
          errorHandler={errorHandler}
          selectedConversationId={selectedConversationId}
          isAssistantChat={isAssistantChat}
        />
      ))}
    </>
  );

  return withExistingGallery ? (
    galleryItems
  ) : (
    <Gallery id={'#' + galleryId} withDownloadButton>
      {galleryItems}
    </Gallery>
  );
}

export default memo(ImageGallery);
