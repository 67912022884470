var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Spinner } from '../Spinner';
import { Icon } from '../Icon';
import styles from './styles.module.scss';
const inputStyles = {
    display: 'block',
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
    border: 'none',
};
const texts = {
    Ready: {
        ru: '<span class="UploadArea__fakeLink">Прикрепите файл</span><wbr /> <span>или перетащите его сюда</span>',
        en: '<span class="UploadArea__fakeLink">Attach a file</span><wbr /> <span>or drag it here</span>',
    },
    DragOver: {
        ru: 'Отпустите файл, чтобы загрузить',
        en: 'Drop the file to upload',
    },
};
export const UploadArea = React.memo((_a) => {
    var { label, description, errorText, disabled, onChange, name, fileName, accept, className, loading, onReset, invalid, language = 'en', size = 'lg', dataTestId, multiple = false, customArea, customFileNameArea } = _a, restProps = __rest(_a, ["label", "description", "errorText", "disabled", "onChange", "name", "fileName", "accept", "className", "loading", "onReset", "invalid", "language", "size", "dataTestId", "multiple", "customArea", "customFileNameArea"]);
    const errorTextInner = invalid ? errorText : '';
    const lang = useMemo(() => {
        return (['ru', 'en'].includes(language) ? language : 'en');
    }, [language]);
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (disabled)
            return;
        onChange && onChange(acceptedFiles, rejectedFiles, name);
    }, [disabled, name, onChange]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, multiple: multiple });
    return (_jsxs("div", { className: styles.FileUpload, "data-test-id": dataTestId, children: [label && _jsx("div", { className: styles.FileUpload__label, children: label }), _jsxs("div", Object.assign({}, getRootProps(), { className: cn(styles.FileUpload__field, styles[`size_${size}`], className, {
                    [styles.FileUpload__field_dragging]: isDragActive && !disabled,
                    [styles.FileUpload__field_dragging_disabled]: disabled,
                    [styles.FileUpload__field_invalid]: invalid,
                    [styles.FileUpload__field_loading]: fileName && loading,
                }) }, restProps, { children: [_jsx("input", Object.assign({ "data-test-id": `${dataTestId}-input` }, getInputProps({ style: inputStyles }), { disabled: disabled })), !isDragActive && !!(fileName === null || fileName === void 0 ? void 0 : fileName.length) ? (_jsx("div", { className: styles.FileUpload__field__content, children: customFileNameArea !== null && customFileNameArea !== void 0 ? customFileNameArea : (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.titleBox, children: [loading ? (_jsx(Spinner, { inline: true, size: 'md' })) : invalid ? (_jsx(Icon, { name: 'farExclamationTriangle', color: 'danger', size: 'md' })) : (_jsx(Icon, { name: 'farCheckCircle', color: 'success', size: 'md' })), _jsx("div", { className: styles.title, children: _jsx("div", { className: styles.title__name, "data-test-id": `${dataTestId}-fileName`, children: fileName }) })] }), !loading && fileName && (_jsx("div", { className: styles.actions, "data-test-id": `${dataTestId}-delete`, onClick: e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onReset === null || onReset === void 0 ? void 0 : onReset();
                                    }, children: _jsx(Icon, { name: 'farTrashAlt', color: 'secondary' }) }))] })) })) : (_jsx("div", { className: styles.emptyBox, children: customArea !== null && customArea !== void 0 ? customArea : (_jsxs(_Fragment, { children: [_jsx("div", { className: cn(styles.readyState, styles.hideWhenDragging), dangerouslySetInnerHTML: { __html: texts['Ready'][lang] } }), _jsx("div", { className: styles.showWhenDragging, children: texts['DragOver'][lang] })] })) }))] })), (description || errorTextInner) && (_jsxs("div", { className: styles.FileUpload__bottom_description, children: [description && _jsx("div", { className: styles.description, dangerouslySetInnerHTML: { __html: description } }), errorTextInner && (_jsx("div", { className: styles.error, "data-test-id": `${dataTestId}-errorText`, children: errorText }))] }))] }));
});
UploadArea.displayName = 'UploadArea';
